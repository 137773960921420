import { Component } from 'react';
import { loadCSS } from 'fg-loadcss';

export default class Basic extends Component {
  componentDidMount(){
    loadCSS(
      'https://s3-us-west-2.amazonaws.com/primeagentmarketing/library/lmg-fa/5.7.1/css/all.min.css',
      document.querySelector('#insertion-point-jss'),
    );
    loadCSS(
      'https://use.typekit.net/kfr7usf.css',
      document.querySelector('#insertion-point-jss'),
    );
    loadCSS(
      'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css',
      document.querySelector('#insertion-point-jss'),
    );
    loadCSS(
      'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css',
      document.querySelector('#insertion-point-jss'),
    );
  }
  render() {
    return null
  }
}