import React, { Component } from 'react';
import axios from 'axios';
import qs from 'querystring';

export default class BookForm extends Component {
  state = {
    bookFilled: false,
    name: '',
    email: '',
    howlong: '',
  }
  componentWillReceiveProps(formProps, state){
    if(formProps.filled){
      this.setState({
        bookFilled: false
      })
    } else {
      return null
    }
  }
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }
  handleSubmit = (e) => {
    const requestBody = {
      name: this.state.name,
      email: this.state.email,
      howlong: this.state.howlong 
    }
    const config = {
      headers:{
        'content-type': 'application/x-www-form-urlencoded'
      }
    }
    e.preventDefault();
    axios.post('/book-form.php', qs.stringify(requestBody), config,).then(response => {
      this.setState({
        bookFilled: true
      })
      console.log(this.state.bookFilled);
    })
  }
  render() {
    let bookForm;
    if(!this.state.bookFilled){
      bookForm = <form className="bookForm" onSubmit={this.handleSubmit}>
      <div class="grid-x">
        <div class="large-6 cell">
          <p data-ani="fadeInUp"><input type="text" name="name" placeholder="Your Name" title="Your Name"  onChange={this.handleChange} /></p>
        </div>
        <div class="large-6 cell">
          <p data-ani="fadeInUp"><input type="text" name="email" placeholder="Your Email" title="Your Email"  onChange={this.handleChange} /></p>
        </div>
        <div class="large-12 cell">
          <p>How long have you been a financial advisor?</p>
          <ul class="radio-list">
            <li data-ani="fadeInUp"><label><input  onChange={this.handleChange} type="radio" name="howlong" value="0-1 year" /> 0-1 Year</label></li>
            <li data-ani="fadeInUp"><label><input  onChange={this.handleChange} type="radio" name="howlong" value="1-3 years" /> 1-3 Years</label></li>
            <li data-ani="fadeInUp"><label><input  onChange={this.handleChange} type="radio" name="howlong" value="3-5 years" /> 3-5 Years</label></li>
            <li data-ani="fadeInUp"><label><input  onChange={this.handleChange} type="radio" name="howlong" value="5-10 years" /> 5-10 Years</label></li>
            <li data-ani="fadeInUp"><label><input  onChange={this.handleChange} type="radio" name="howlong" value="10+ years" /> 10+ Years</label></li>
          </ul>
        </div>
        <div class="large-12 cell">
          <p data-ani="fadeInUp"><input type="submit" value="Submit" class="button" /></p>
        </div>
      </div>
      </form>
    } else {
      bookForm = <p>Thanks for filling out our form.  You will receive an email with more information.</p>
    }
    return (
      <>
        {bookForm}
      </>
    )
  }
}


