import React, { Component } from "react";
import Helmet from "react-helmet";
import Basic from '../scripts/basic';
import { NavLink } from 'react-router-dom';

export default class Testimonials extends Component {
  openPop = (e) => {
    e.preventDefault();
    let popName = 'startedPop';
    document.getElementById(popName).classList.add('active-pop');
    this.setState({ isMobile: false });
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('mobile-active');
  }
  render() {
    return (
      <div>
      <div className="transition-overlay"></div>
        <Basic/>
        <Helmet>
          <title>What Our Advisors Say | 7-Tool Advisor by One Seven</title>
          <meta property="og:image" content="https://7tooladvisor.com/images/istockphoto-654231348-1024x1024.jpg" />
        </Helmet>
        <div className="pageContent testimonials">
          <div className="innerContent">
            <div className="wrap">
              <div className="titleContainer">
                <div className="bg-wrapper testimonials"></div>
                <div className="bg-flex-wrapper" dangerouslySetInnerHTML={{__html: this.props.bg}} />
                <h1>
                What Our Advisors Say
                </h1>
                <i className="arrow-down lmg-fal lmg-fa-chevron-down animated flash"></i>
              </div>
            </div>
          </div>
          <div className="int-tier testimonials-tier1" id="tier1">
            <div className="wrap text-center">
              <h2 data-ani="fadeInUp">Kind Words from One Seven Advisors</h2>
            </div>
          </div>
          <div className="int-tier testimonials-tier">
            <div className="wrap">
              <div className="grid-x grid-padding-x align-middle">
                <div className="large-5 cell">
                  <img src="/images/joe-headshot.jpg" alt="" />
                </div>
                <div className="large-7 cell testimonial-container">
                  <p>&ldquo;Couldn’t be happier with the job that Louise and Veronica have done with this transition. Day and night compared to my past experience...Rockstars!&rdquo;</p>
                  <p className="testimonial-author">
                    &mdash; Joe G.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="int-tier testimonials-tier">
            <div className="wrap">
              <div className="grid-x grid-padding-x align-middle">
              <div className="large-5 large-order-2 cell">
                  <img src="/images/tami-headshot.jpg" alt="" />
                </div>
                <div className="large-7 large-order-1 ccell testimonial-container">
                  <p className="pointsmaller">&ldquo;Thanks for all of the help so far!  It’s great to be part of a well-run practice. After 25 years in the Financial Services industry, I am looking for a home for me and my clients— a well-run business that can help me better care for the families that trust me with their finances. One Seven is a place that can help me grow. I’m very impressed about how easy the transition process has been so far, and Brenda has been so reliable and helpful with sorting out all the moving parts.&rdquo;</p>
                  <p className="testimonial-author">
                    &mdash; Tami A.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cta-bar text-center">
          <p>
            <a className="cta-button" data-pop-name="startedPop" href="#" onClick={this.openPop}><span>Continue the Journey</span> <span>9 of 10</span></a>
          </p>
        </div>
      </div>
    );
  }
}
