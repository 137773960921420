import React, { Component } from "react";
import Helmet from "react-helmet";
import Basic from '../scripts/basic';
import { NavLink } from 'react-router-dom';
import axios from 'axios';

export default class BlogPost extends Component {
  state = {
    blog: '',
    isLoading: true,
  }
  componentDidMount(){
    axios.get('https://weareoneseven.com/wp-json/seventool/v1/blog?slug=' + this.props.match.params.slug) 
    .then(res => {
      const blog = res.data.posts;
      this.setState({ 
        blog: blog, 
        isLoading: false
      })
    }).catch(error => {
      console.log(this);
    })
  }
  render() {
    let blog,
    author,
    item = this.state.blog[0],
    postImage,
    postTitle;
    if(this.state.isLoading){
      blog = "Content is loading...";
    } else {
      if(this.state.blog !== false){
        blog = 
        <div className="blog-post">
          <div className="grid-x grid-padding-x grid-margin-x">
            <div className="large-3 cell">
              <img src={item.thumbnail ? item.thumbnail : '//placehold.it/350x400/000000?text=7-Tool Advisor'} alt={item.title + " thumbnail"} />
            </div>
            <div className="large-9 cell">
              <div className="blog-meta">
                <h2>{item.title}</h2>
                <h3><i className="lmg-fas lmg-fa-calendar-alt"></i>Posted on {item.date} by {item.author}</h3>
              </div>
              <div dangerouslySetInnerHTML={{__html: item.content }} />
            </div>
          </div>
          <div className="about-author">
            <div className="grid-x grid-padding-x grid-padding-y grid-margin-y grid-margin-x align-middle">
              <div className="large-3 cell author-headshot">
                <img src={item.headshot ? item.headshot : '//placehold.it/250x250/000000?text=7-Tool Advisor Headshot'} alt={item.title + " thumbnail"} />
              </div>
              <div className="large-9 cell author-details">
                <h2 className="author-about">About the Author</h2>
                <h3 className="author-name">{item.author_name}</h3>
                {item.author_email ? <p><strong>Email: </strong><a href={"mailto:" + item.author_email}>{item.author_email}</a></p> : ''}
                {item.author_phone ? <p><strong>Call: </strong><a href={"tel:" + item.author_phone}>{item.author_phone}</a></p> : ''}
                {item.author_facebook || item.author_linkedin ? 
                  <ul className="author-sm">
                    <li><a href={item.author_linkedin} target="_blank" className="lmg-fab lmg-fa-linkedin-in"></a></li>
                    <li><a href={item.author_facebook} target="_blank" className="lmg-fab lmg-fa-facebook-f"></a></li>
                  </ul> 
                : ''}
              </div>
            </div>
          </div>
        </div>;
        postTitle = item.title;
        postImage = item.thumbail;
      }
    }
    return (
      <div>
      <div className="transition-overlay"></div>
        <Basic/>
        <Helmet>
          <title>{postTitle + " | Blog | 7-Tool Advisor by One Seven"}</title>
          <meta property="og:image" content={postImage} />
        </Helmet>
        <div className="pageContent">
          <div className="innerContent">
            <div className="wrap">
              <div className="titleContainer">
                <div className="bg-wrapper culture-header"></div>
                <div className="bg-flex-wrapper" dangerouslySetInnerHTML={{__html: this.props.bg}} />
                <h1 className="blog-post-title">
                  {postTitle}
                </h1>
                <i className="arrow-down lmg-fal lmg-fa-chevron-down animated flash"></i>
              </div>
              <div className="int-tier" id="tier1">
                <div className="wrap">
                  {blog}
                  {author}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
