import React, { Component } from 'react';
import axios from 'axios';
import qs from 'querystring';

export default class DelayForm extends Component {
  state = {
    delayFilled: false,
    name: '',
    email: '',
  }
  componentWillReceiveProps(formProps, state){
    if(formProps.filled){
      // console.log(formProps.filled)
      this.setState({
        delayFilled: false
      })
    } else {
      // console.log(formProps.filled)
      return null
    }
  }
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }
  handleSubmit = (e) => {
    const requestBody = {
      name: this.state.name,
      email: this.state.email
    }
    const config = {
      headers:{
        'content-type': 'application/x-www-form-urlencoded'
      }
    }
    e.preventDefault();
    axios.post('/form-delay.php', qs.stringify(requestBody), config,).then(response => {
      this.setState({
        delayFilled: true
      })
    })
    // console.log(this.state.delayFilled)
  }
  render() {
    let delayForm;
    if(!this.state.delayFilled){
      delayForm = <form onSubmit={this.handleSubmit}>
        <div className="grid-x grid-padding-x">
          <div className="large-6 cell">
            <p><label className="sendOff" htmlFor="namePop">Name</label><input id="namePop" name="name" type="text" placeholder="Name" onChange={this.handleChange} /></p>
          </div>
          <div className="large-6 cell">
            <p><label className="sendOff" htmlFor="emailPop">Email</label><input id="emailPop" name="email" type="text" placeholder="Email" onChange={this.handleChange}  /></p>
          </div>
          <div className="large-12 cell">
            <p><input type="submit" className="button" value="Submit" /></p>
          </div>
        </div>
      </form>
    } else {
      delayForm = <p>Thank you for reaching out!  We will be in touch as soon as possible.</p>
    }
    return (
      <>
        {delayForm}
      </>
    )
  }
}