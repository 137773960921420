import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import LoadCSS from './scripts/loadCSS';
import Popover from './popover';
import {Helmet} from 'react-helmet';

export default class Header extends Component {
  state = {
    isTop: true,
    isSticky: false,
    isTool: false
  };

  componentDidMount() {
    setTimeout(function(){
      document.body.classList.add('loaded')
    }, 500)
    window.onload = (e) => {
      if(document.getElementById('sevenTool')){
        this.setState({
          isTool: true
        })
      }

    }
    document.addEventListener("scroll", () => {
      const isTop = window.scrollY < 100;
      if (isTop !== this.state.isTop) {
        this.setState({ isTop });
      }
      if(window.scrollY > 30){
        document.body.classList.add('sticky-header')
        this.setState({ isSticky: true })
      } else {
        document.body.classList.remove('sticky-header')
        this.setState({ isSticky: false })
      }
    });
  }
  render() {
    const isSevenTool = this.state.isTool;
    let headerImage;
    if(isSevenTool){
      headerImage = <NavLink to="/"><img src={this.state.isSticky ? '/images/7ta-2022-logo-white.svg' : '/images/7ta-2022-logo-white.svg'} alt="" /></NavLink>;
    } else {
      headerImage = <NavLink to="/"><img src={this.state.isSticky ? '/images/7ta-2022-logo-dark.svg' : '/images/7ta-2022-logo-white.svg'} alt="" /></NavLink>;
    }
    const zoomPixel = (function () {
        var zi = document.createElement('script');
        zi.type = 'text/javascript';
        zi.async = true;
        zi.referrerPolicy = 'unsafe-url';
        zi.src = 'https://ws.zoominfo.com/pixel/62ed31b5aa40c5008ffc6d23';
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(zi, s);
      })();;
    return (
      <>
        <Helmet>
          <script>
            {zoomPixel}
          </script>
        </Helmet>
        <Popover/>
        <LoadCSS/>
        <header className="siteHeader animated slideInDown">
          <div className="logoLeft">
            {headerImage}
          </div>
          <div className="headerRight">
          </div>
        </header>
      </>
    );
  }
}
