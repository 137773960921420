import React, { Component } from 'react';
import axios from 'axios';
import qs from 'querystring';

export default class NavForm extends Component {
  state = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    formFilled: false
  }
  componentWillReceiveProps(formProps, state){
    if(formProps.filled){
      // console.log(formProps.filled)
      this.setState({
        formFilled: false
      })
    } else {
      // console.log(formProps.filled)
      return null
    }
  }
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }
  handleSubmit = (e) => {
    const requestBody = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      emailAddress: this.state.emailAddress,
    }
    const config = {
      headers:{
        'content-type': 'application/x-www-form-urlencoded'
      }
    }
    e.preventDefault();
    axios.post('/form-nav.php', qs.stringify(requestBody), config,).then(response => {
      this.setState({
        formFilled: true
      })
    })
  }
  render() {
    let whitePaperForm;
    if(!this.state.formFilled){
      whitePaperForm = <form onSubmit={this.handleSubmit}>
      <div className="grid-x grid-padding-x align-center text-center">
        <div className="large-12 cell">
          <h2 className="text-center formtitle"><span>Discover the Seven Pillars of Wellness</span> Schedule Your Dream Meeting</h2>
        </div>
        <div className="medium-4 cell">
          <p><label className="sendOff" htmlFor="firstNameDream">First Name</label><input id="firstNameDream" name="firstName" type="text" placeholder="First Name" onChange={this.handleChange} /></p>
        </div>
        <div className="medium-4 cell">
          <p><label className="sendOff" htmlFor="lastNameDream">Last Name</label><input id="lastNameDream" name="lastName" type="text" placeholder="Last Name" onChange={this.handleChange} /></p>
        </div>
        <div className="medium-4 cell">
          <p><label className="sendOff" htmlFor="emailAddressDream">Email Address</label><input id="emailAddressDream" name="emailAddress" type="text" placeholder="Email Address" onChange={this.handleChange} /></p>
        </div>
        <div className="large-12 cell">
          <p className="nomargin"><input type="submit" className="button" value="Submit" /></p>
        </div>
      </div>
    </form>
    } else {
      whitePaperForm = <div>
        <div className="large-12 cell">
          <h2 className="text-center formtitle"><span>Discover the Seven Pillars of Wellness</span> Schedule Your Dream Meeting</h2>
        </div>
        <div className="large-12 cell text-center">
          <p>Thank you for your interest. We’ll be in touch soon with more information.</p>
        </div>
      </div>
    } 
    return (
      <>
        <div className="whitePaperForm">
          {whitePaperForm}
        </div>
      </>
    )
  }
}