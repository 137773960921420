import { Component } from 'react';
import jQuery from 'jquery';
import $ from 'jquery';

export default class Basic extends Component {
  state = {
    backg: ''
  }
  componentDidMount(){
    if(document.getElementsByClassName('bg-flex-wrapper').length > 0) {
      let body = document.getElementsByTagName('body')[0];
      body.classList.remove('random' + localStorage.getItem('bg'))
      var getRandomBg = function() {
        return (Math.floor(Math.random() * 11) + 1);
      };
      var bg;
      do {
          bg = getRandomBg();
      } while (bg === localStorage.getItem('bg'));
      localStorage.setItem('bg', bg);
      body.classList.add('random' + bg);
      if(localStorage.getItem('bg') === '1'){
        document.getElementsByClassName('bg-flex-wrapper')[0].innerHTML = '<div class="bg-flex random1"><div class="caption"><p>1. JP Williams and Family<br/> 2. The beach life for Kelly Pannitto<br/> 3. Weekend hiking for Ron Gross and family</p></div><div class="grid-x"><div class="small-4 cell"></div><div class="small-4 cell"></div><div class="small-4 cell"></div></div></div>'
      } else if(localStorage.getItem('bg') === '2'){
        document.getElementsByClassName('bg-flex-wrapper')[0].innerHTML = '<div class="bg-flex random2"><div class="caption"><p>1. Morgan Irvin paddleboarding with friends<br/> 2. 7TA Coach Mike Petramalo horse back riding the FL beaches<br/> 3. Sam and Erica Gross out for a hike</p></div><div class="grid-x"><div class="small-4 cell"></div><div class="small-4 cell"></div><div class="small-4 cell"></div></div></div>';
      } else if(localStorage.getItem('bg') === '3'){
        document.getElementsByClassName('bg-flex-wrapper')[0].innerHTML = '<div class="bg-flex random3"><div class="caption"><p>1. Todd Resnick Biking the Colorado Mountains<br/>2. Enjoying the open water</p></div><div class="grid-x"><div class="small-6 cell"></div><div class="small-6 cell"></div></div></div>';
      } else if(localStorage.getItem('bg') === '4'){
        document.getElementsByClassName('bg-flex-wrapper')[0].innerHTML = '<div class="bg-flex random4"><div class="caption"><p>1. Todd Resnick and family spend a day on the river<br/>2. John Conry experiencing the fresh CO air</p></div><div class="grid-x"><div class="small-6 cell"></div><div class="small-6 cell"></div></div></div>';
      } else if(localStorage.getItem('bg') === '5'){
        document.getElementsByClassName('bg-flex-wrapper')[0].innerHTML = '<div class="bg-flex random5"><div class="caption"><p>1. Niki and Todd Resnick exploring Ohio\'s waterfalls<br/>2. John Conry experiencing the fresh CO air</p></div><div class="grid-x"><div class="small-6 cell"></div><div class="small-6 cell"></div></div></div>';
      } else if(localStorage.getItem('bg') === '6'){
        document.getElementsByClassName('bg-flex-wrapper')[0].innerHTML = '<div class="bg-flex random6"><div class="caption"><p>1. Kelly Pannitto living the beach life<br/> 2. A journey through the trees<br/> 3. Day on the water for Mike Petramalo</p></div><div class="grid-x"><div class="small-4 cell"></div><div class="small-4 cell"></div><div class="small-4 cell"></div></div></div>';
      } else if(localStorage.getItem('bg') === '7'){
        document.getElementsByClassName('bg-flex-wrapper')[0].innerHTML = '<div class="bg-flex random7"><div class="caption"><p>1. A day on the road for Richard Gluck<br/> 2. The beauty of the rising morning sun</p></div><div class="grid-x"><div class="small-6 cell"></div><div class="small-6 cell"></div></div></div>';
      } else if(localStorage.getItem('bg') === '8'){
        document.getElementsByClassName('bg-flex-wrapper')[0].innerHTML = '<div class="bg-flex random8"><div class="caption"><p>1. Ready for some climbing<br/> 2. Don Todd and family hiking the N. Georgia mountains<br/> 3. Patrick Moore hikes through CO</p></div><div class="grid-x"><div class="small-4 cell"></div><div class="small-4 cell"></div><div class="small-4 cell"></div></div></div>';
      } else if(localStorage.getItem('bg') === '9'){
        document.getElementsByClassName('bg-flex-wrapper')[0].innerHTML = '<div class="bg-flex random9"><div class="caption"><p>1. Taking in the mountains<br/> 2. The Resnick Family journeys through Costa Rica</p></div><div class="grid-x"><div class="small-6 cell"></div><div class="small-6 cell"></div></div></div>';
      } else if(localStorage.getItem('bg') === '10'){
        document.getElementsByClassName('bg-flex-wrapper')[0].innerHTML = '<div class="bg-flex random10"><div class="caption"><p>1. Veronica Mounts goes West<br/> 2. The Glucks enjoying time together</p></div><div class="grid-x"><div class="small-6 cell"></div><div class="small-6 cell"></div></div></div>';
      } else if(localStorage.getItem('bg') === '11'){
        document.getElementsByClassName('bg-flex-wrapper')[0].innerHTML = '<div class="bg-flex random11"><div class="caption"><p>1. Morning walk on the beach<br/> 2. Matt Brigeman\'s hike of Camelback</p></div><div class="grid-x"><div class="small-6 cell"></div><div class="small-6 cell"></div></div></div>';
      } else {
        document.getElementsByClassName('bg-flex-wrapper')[0].innerHTML = '<div class="bg-flex random11"><div class="caption"><p>1. Morning walk on the beach<br/> 2. Matt Brigeman\'s hike of Camelback</p></div><div class="grid-x"><div class="small-6 cell"></div><div class="small-6 cell"></div></div></div>';
      }
    }
    window.jQuery = jQuery;
    require('letteringjs');
    require('textillate');
    $('.arrow-down').click(function(){
      $('body, html').animate({
        scrollTop: $('#tier1').offset().top - 70
      }, 1500)
    })
    setTimeout(function(){
      $('.titleContainer h1').textillate({ 
        in: { 
          effect: 'fadeInDown',
          shuffle: true
        }
      });
    }, 500)
    const animateMe = function(){
      if($('[data-ani], [data-class]').length > 0){
        $('[data-ani], [data-class]').each(function(i){
          var bottom_of_object = $(this).offset().top + ($(this).outerHeight() * .25);
          var bottom_of_object_2 = $(this).offset().top + $(this).outerHeight();
          var boo_2 =  $(this).offset().top;
          var bottom_of_window = $(window).scrollTop() + $(window).height();
          /* If the object is completely visible in the window, fade it it */
          var aniIn = $(this).attr('data-ani');
          if($(window).scrollTop() > bottom_of_object_2 || bottom_of_window < boo_2){
            if($(this).attr('data-class')){
              $(this).removeClass($(this).attr('data-class'));
            } else {
              setTimeout(function(){
                $(this).removeClass('animated ' + aniIn);
              }, 500)
            }
          }
          if( bottom_of_window > bottom_of_object){
            if($(this).attr('data-class')){
              $(this).addClass($(this).attr('data-class'));
            } else {
              $(this).addClass('animated ' + $(this).attr('data-ani'));
              $(this).removeClass('animateme');
            }
          }
          if($(this).attr('data-delay')){
            $(this).css({
              "animation-delay": $(this).attr('data-delay') + 's'
            })
          }
        });
      }
    }
    const textillateMe = (dataTextillate) => {
      if($('[data-textillate-ani]').length > 0){
        $('[data-textillate-ani]').each(function(){
          var bottom_of_object = $(this).offset().top + $(this).outerHeight() * .25;
          var bottom_of_window = $(window).scrollTop() + $(window).height();
          if( bottom_of_window > bottom_of_object ){
            $(this).textillate({ 
              in: { 
                effect: 'fadeInDown',
                shuffle: true,
                delayScale: .5,
              }
            });
          }
        })
      }
    }
    let dataAnimate = document.querySelectorAll('[data-ani]');
    let dataTextillate = document.querySelectorAll('[data-textillate-ani]');
    animateMe(dataAnimate);
    textillateMe(dataTextillate);
    document.addEventListener("scroll", () => {
      animateMe(dataAnimate);
      textillateMe(dataTextillate);
    });
  }
  render() {
    return null
  }
}