import React, { Component } from "react";
import Helmet from "react-helmet";
import BookForm from "../forms/book-form";
import Testimonials from "../testimonials";
import { NavLink } from 'react-router-dom';
import Basic from '../scripts/basic';

export default class Journey extends Component {
  render() {
    return (
      <div>
      <div className="transition-overlay"></div>
        <Basic/>
        <Helmet>
          <title>Become a Badass Financial Advisor Book | 7-Tool Advisor by One Seven</title>
          <meta property="og:image" content="https://7tooladvisor.com/images/istockphoto-537459698-1024x1024.jpg" />
        </Helmet>
        <div className="pageContent">
          <div className="innerContent">
            <div className="wrap">
              <div className="titleContainer bookTitle">
                <div className="bg-wrapper book-bg"></div>
                <h1 class="bookTitleH1">
                  Become a Badass Financial Advisor Book
                </h1>
                <i className="arrow-down lmg-fal lmg-fa-chevron-down animated flash">
                </i>
              </div>
              <div className="int-tier tier1 book-tier-1" id="tier1">
                <div className="wrap">
                  <div className="grid-x align-middle">
                    <div className="large-4 cell photo-container">
                      <div className="photo-cover" data-ani="slideOutRight"></div>
                      <img src="/images/BadAssAdvisor-Book-Mock.jpg" alt="" />
                    </div>
                    <div className="large-8 cell">
                      <div className="tier-content">
                        <div className="tierTitle">
                          <h2 data-textillate-ani>
                          Become a BAD*SS Financial Advisor: Successful Solutions for Building a Personal & Professional Legacy
                          </h2>
                        </div>
                        <div data-ani="fadeInUp">
                          <p>Do you have ambitions to create your own advisory practice - one that better serves both your own personal and professional needs, as well as those of your clients? If so, what's holding you back? Perhaps you're not sure where to start, or find yourself leaning on excuses such as "waiting for the right time" or "hoping or a potential promotion" at your current firm.</p>
                          <p><strong>We're here to tell you that the time to make a change is NOW, and this book is the starting point.</strong></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="int-tier tier2 book-tier-2">
                <div className="wrap">
                  <div className="inner-wrap">
                    <div class="grid-x">
                      <div class="large-6 cell">
                        <p data-ani="fadeInUp">Having successfully built and scaled or own dream financial services business, One Seven, we developed "Become a Badass Financial Advisor" to help you do the same - whatever your dream may look like. This book will give you the confidence to go beyond status-quo options, building a plan that holistically captures what you're working toward and how to get there. We draw from our own experiences, as well as those of fellow advisors who've successfully made the leap, to guide you in pushing through discomfort and equipping yourself with the tools needed to build a fulfilling, long-lasting financial legacy.</p>
                        <p data-ani="fadeInUp"><strong>Don't wait any longer. Embrace the total BADASS ADVISOR<br/>  you are to realize success for yourself, your family, and<br/> your future - your way!</strong></p>
                      </div>
                      <div class="large-6 cell second-cell">
                        <div>
                          <h2 data-textillate-ani>Download Your FREE Copy Now!</h2>
                          <BookForm />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="int-tier tier1 book-tier-3">
                <div className="wrap">
                  <h2 data-textillate-ani>About the Authors</h2>
                  <div className="grid-x">
                    <div className="large-6 cell">
                      <div className="inner-cell">
                        <p data-ani="fadeInUp"><img src="/images/ronald-book-photo.jpg" alt="Ronald" /></p>
                        <h3 data-textillate-ani>Ronald Gross</h3>
                        <p data-ani="fadeInUp">Ron is the President & CEO of MGO Investment Advisors and the Co-Founder of One Seven. With 35 years' financial planning experience, Ron channeled the skills learned over many years spent running a successful business into starting One Seven from the ground up - creating a space and culture for financial advisors outside the industry norm. His motivation is to help people achieve their life goals, specifically through wealth management guidance and mentorship. This path has helped him realize his own work-life goals, balancing a successful career with time spent with his family, studying European history, coaching youth wrestling, and volunteering.</p>
                      </div>
                    </div>
                    <div className="large-6 cell second-cell">
                      <div className="inner-cell">
                        <p data-ani="fadeInUp"><img src="/images/todd-photo-book.jpg" alt="Todd" /></p>
                        <h3 data-textillate-ani>Todd Resnick</h3>
                        <p data-ani="fadeInUp">Todd is a Co-Founder and President of One Seven. With nearly 15 years' experience in financial planning, Todd has built a career focused on impacting people's lives for the better and creating meaningful relationships. He's passionate about equipping financial planners with the motivation and tools to realize their own definition of personal and professional fulfillment - and in turn, helping their clients to do the same. Todd does so for himself, as well, balancing his professional drive with time spent traveling, volunteering with his wife and their children's schools, and following all things Cleveland, his hometown - including sports, music, theatre, and the arts.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="int-tier tier2 book-tier-4">
                <div className="wrap">
                  <div className="grid-x">
                    <div className="large-6 cell">
                      <div className="inner-cell">
                        <div class="title-cell-chapters">
                          <h2 data-textillate-ani>What's Inside?</h2>
                        </div>
                        <ul class="inside-list">
                          <li data-ani="fadeInUp">Stop Waiting to Have the High-Growth Business You Want</li>
                          <li data-ani="fadeInUp">Define Your Dream Practice</li>
                          <li data-ani="fadeInUp">Embrace the Qualities of a Great Advisor</li>
                          <li data-ani="fadeInUp">Avoid Common Obstacles to Growth</li>
                          <li data-ani="fadeInUp">Beginning Your Journey to Personal and Professional Growth</li>
                          <li data-ani="fadeInUp">And more!</li>
                        </ul>
                      </div>
                    </div>
                    <div className="large-6 cell second-cell">
                      <div className="inner-cell">
                        <h2 data-textillate-ani>Download Your FREE Copy Now!</h2>
                        <BookForm />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="int-tier tier1 book-tier-5">
                <div className="wrap">
                  <div class="testimonials-container">
                    <Testimonials />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
