import React, { Component } from "react";
import Helmet from "react-helmet";
import Basic from '../scripts/basic';
import { NavLink } from 'react-router-dom';

export default class Transition extends Component {
  openPop = (e) => {
    let popName = 'startedPop';
    document.getElementById(popName).classList.add('active-pop');
  }
  componentDidMount(){

  }
  render() {
    return (
      <div>
      <div className="transition-overlay"></div>
        <Basic/>
        <Helmet>
          <title>Your Journey to One Seven  | 7-Tool Advisor by One Seven</title>
          <meta property="og:image" content="https://7tooladvisor.com/images/transition-header.jpg" />
        </Helmet>
        <div className="pageContent">
          <div className="innerContent">
            <div className="wrap">
              <div className="titleContainer">
                <div className="bg-wrapper transition"></div>
                <div className="bg-flex-wrapper" dangerouslySetInnerHTML={{__html: this.props.bg}} />
                <h1>
                Your Journey to One Seven
                </h1>
                <i className="arrow-down lmg-fal lmg-fa-chevron-down animated flash"></i>
              </div>
              <div className="int-tier transition-tier1" id="tier1">
                <div className="wrap">
                  <h2 className="text-center" data-ani="fadeInUp">The first seven steps of<br className="show-for-large" /> your One Seven journey…</h2>
                  <div className="process-container notop" data-ani="fadeInUp" >
                    <div className="inner-process">
                      <div className="grid-x">
                        <div className="large-12 cell">

                          <h2 data-textillate-ani>Getting to Know You</h2>
                          <ul>
                            <li data-ani="fadeInUp">Us</li>
                            <li data-ani="fadeInUp">You</li>
                            <li data-ani="fadeInUp">Why</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="process-container" data-ani="fadeInUp">
                    <div className="inner-process">
                      <div className="grid-x">
                        <div className="large-7 cell left-text">
   
                          <h2 data-textillate-ani>Dig Deeper</h2>
                          <ul>
                            <li data-ani="fadeInUp">PAQ Review</li>
                            <li data-ani="fadeInUp">Vision & Value</li>
                            <li data-ani="fadeInUp">What We Offer</li>
                          </ul>
                        </div>
                        <div className="large-5 cell image-side">
                          <div className="process-image dig-deeper">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="process-container notop" data-ani="fadeInUp" >
                    <div className="inner-process">
                      <div className="grid-x">
                        <div className="large-12 cell">
                          <h2 data-textillate-ani>Your Solution</h2>
                          <ul>
                            <li data-ani="fadeInUp">Explore Options</li>
                            <li data-ani="fadeInUp">Customize</li>
                            <li data-ani="fadeInUp">Present</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="process-container" data-ani="fadeInUp">
                    <div className="inner-process">
                      <div className="grid-x">
                      <div className="large-5 cell image-side">
                          <div className="process-image make-the-call">
                          </div>
                        </div>
                        <div className="large-7 cell left-text">
                          <h2 data-textillate-ani>Make the Call</h2>
                          <ul>
                            <li data-ani="fadeInUp">Signing MOU</li>
                            <li data-ani="fadeInUp">Set Date</li>
                            <li data-ani="fadeInUp">Timeline</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="process-container notop" data-ani="fadeInUp" >
                    <div className="inner-process">
                      <div className="grid-x">
                        <div className="large-12 cell">
                          <h2 data-textillate-ani>Start the Clock</h2>
                          <ul>
                            <li data-ani="fadeInUp">Our Role</li>
                            <li data-ani="fadeInUp">Your Role</li>
                            <li data-ani="fadeInUp">Training 101</li>
                            <li data-ani="fadeInUp">Meet Your Transition Team</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="process-container ind-day" data-ani="fadeInUp">
                    <div className="inner-process">
                      <div className="grid-x">
                        <div className="large-7 cell left-text">
                          <h2 data-textillate-ani>Independence Day!</h2>
                          <ul>
                            <li data-ani="fadeInUp">New Office</li>
                            <li data-ani="fadeInUp">Client Calls</li>
                            <li data-ani="fadeInUp">Licenses</li>
                          </ul>
                        </div>
                        <div className="large-5 cell image-side">
                          <div className="process-image independence-day">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="process-container notop" data-ani="fadeInUp" >
                    <div className="inner-process">
                      <div className="grid-x">
                        <div className="large-12 cell">
                          <h2 data-textillate-ani>1<sup>st</sup> 90 Days</h2>
                          <ul>
                            <li data-ani="fadeInUp">Client Onboarding</li>
                            <li data-ani="fadeInUp">Training 201</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="text-center"><button class="button" onClick={this.openPop}>Get Started</button></p>
              </div>
            </div>
          </div>
        </div>
        <div className="cta-bar text-center">
          <p>
            <NavLink className="cta-button" to="/testimonials"><span>Continue the Journey</span> <span>8 of 10</span></NavLink>
          </p>
        </div>
      </div>
    );
  }
}
