import React, { Component } from "react";
import Helmet from "react-helmet";
import Basic from '../scripts/basic';
import { NavLink } from 'react-router-dom';
import axios from 'axios';

export default class FeaturedIn extends Component {
  state = {
    blog: [],
    isLoading: true,
  }
  areYouSure = (e) => {
    if(window.confirm('Are you sure you want to leave the 7-Tool Advisor website?')){

    } else {
      e.preventDefault();
    }
  }
  componentDidMount(){
    axios.get('https://weareoneseven.com/wp-json/seventool/v1/featured-in') 
    .then(res => {
      const blog = res.data.posts;
      this.setState({ 
        blog: blog, 
        isLoading: false
      })
    }).catch(error => {
      console.log(this);
    })
  }
  render() {
    let blog;
    if(this.state.isLoading){
      blog = "Content is loading...";
    } else {
      if(this.state.blog !== false){
        blog = this.state.blog.map((item, i) => {
          return(
            <div className="blog-post" key={item.id}>
              <div className="grid-x grid-padding-x grid-margin-x align-center">
                <div className="large-10 cell">
                  <img className="featuredInImage" src={item.thumbnail ? item.thumbnail : '//placehold.it/350x400/000000?text=7-Tool Advisor'} alt={item.title + " thumbnail"} />
                  <div className="blog-meta featured-in-meta">
                    <h2>{item.title}</h2>
                    <div className="blog-excerpt">
                      <p dangerouslySetInnerHTML={{__html: item.excerpt}} />
                      <p><a onClick={this.areYouSure} href={item.link} className="button" target="_blank">Continue Reading</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })
      }
    }
    return (
      <div>
      <div className="transition-overlay"></div>
        <Basic/>
        <Helmet>
          <title>Featured In | 7-Tool Advisor by One Seven</title>
          <meta property="og:image" content="https://7tooladvisor.com/images/transition-header.jpg" />
        </Helmet>
        <div className="pageContent">
          <div className="innerContent">
            <div className="wrap">
              <div className="titleContainer">
                <div className="bg-wrapper culture-header"></div>
                <div className="bg-flex-wrapper" dangerouslySetInnerHTML={{__html: this.props.bg}} />
                <h1>
                  Featured In
                </h1>
                <i className="arrow-down lmg-fal lmg-fa-chevron-down animated flash"></i>
              </div>
              <div className="int-tier" id="tier1">
                <div className="wrap">
                  {blog}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
