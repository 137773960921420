import React, { Component } from "react";
import Helmet from "react-helmet";
import Basic from '../scripts/basic';
import { NavLink } from 'react-router-dom';

export default class Team extends Component {
  openPop = (e) => {
    let popName = e.target.dataset.popName;
    document.getElementById(popName).classList.add('active-pop');
  }
  closePop = (e) => {
    document.getElementsByClassName('active-pop')[0].classList.remove('active-pop');
  }
  componentDidMount(){

  }
  render() {
    return (
      <div>
      <div className="transition-overlay"></div>
        <Basic/>
        <Helmet>
          <title>Our Leadership Team | 7-Tool Advisor by One Seven</title>
          <meta property="og:image" content="https://7tooladvisor.com/images/istockphoto-1164878594-1024x1024.jpg" />
        </Helmet>
        <div className="pageContent">
          <div className="innerContent">
            <div className="wrap">
              <div className="titleContainer">
                <div className="bg-wrapper team-header"></div>
                <div className="bg-flex-wrapper" dangerouslySetInnerHTML={{__html: this.props.bg}} />
                <h1>
                  Our Leadership Team
                </h1>
                <i className="arrow-down lmg-fal lmg-fa-chevron-down animated flash"></i>
              </div>
            </div>
          </div>
          <div className="int-tier team-tier" id="tier1">
            <div className="wrap">
              <div className="grid-x grid-padding-x grid-margin-x grid-margin-y grid-padding-y align-middle align-center">
                <div className="large-4 medium-6 cell" data-ani="fadeInUp">
                  <a className="overlay-link show-for-large" onClick={this.openPop} data-pop-name="todd"></a>
                  <div className="headshotContainer">
                    <img src="/images/todd-headshot.png" alt="Photo of Todd" />
                    <div class="headshotHover">
                      <p><i class="lmg-fal lmg-fa-expand-arrows"></i><br/>Read About Todd</p>
                    </div>
                  </div>
                  <div class="team-title">
                    <h2>Todd Resnick</h2>
                    <h3>Co-Founder President</h3>
                  </div>
                </div>
                <div className="large-4 medium-6 cell" data-ani="fadeInUp">
                  <a className="overlay-link show-for-large" onClick={this.openPop} data-pop-name="ronald"></a>
                  <div className="headshotContainer">
                    <img src="/images/ronald-headshot.png" alt="Photo of Ronald" />
                    <div class="headshotHover">
                      <p><i class="lmg-fal lmg-fa-expand-arrows"></i><br/>Read About Ronald</p>
                    </div>
                  </div>
                  <div class="team-title">
                    <h2>Ronald Gross</h2>
                    <h3>Co-Founder, CEO</h3>
                  </div>
                </div>
                <div className="large-4 medium-6 cell" data-ani="fadeInUp">
                  <a className="overlay-link show-for-large" onClick={this.openPop} data-pop-name="richard"></a>
                  <div className="headshotContainer">
                    <img src="/images/richard-headshot.png" alt="Photo of Richard" />
                    <div class="headshotHover">
                      <p><i class="lmg-fal lmg-fa-expand-arrows"></i><br/>Read About Richard</p>
                    </div>
                  </div>
                  <div class="team-title">
                    <h2>Richard Gross</h2>
                    <h3>Co-Founder, CEO</h3>
                  </div>
                </div>
                <div className="large-4 medium-6 cell" data-ani="fadeInUp">
                  <a className="overlay-link show-for-large" onClick={this.openPop} data-pop-name="mike"></a>
                  <div className="headshotContainer">
                      <img src="/images/mike-headshot.jpg" alt="Photo of Mike" />
                    <div class="headshotHover">
                      <p><i class="lmg-fal lmg-fa-expand-arrows"></i><br/>Read About Mike</p>
                    </div>
                  </div>
                  <div class="team-title">
                    <h2>Mike Petramalo</h2>
                    <h3>Head of Advisor and Business Development</h3>
                  </div>
                </div>
                <div className="large-4 medium-6 cell" data-ani="fadeInUp">
                  <a className="overlay-link show-for-large" onClick={this.openPop} data-pop-name="kelly"></a>
                  <div className="headshotContainer">
                      <img src="/images/kelly-p-headshot.jpg" alt="Photo of Kelly" />
                    <div class="headshotHover">
                      <p><i class="lmg-fal lmg-fa-expand-arrows"></i><br/>Read About Kelly</p>
                    </div>
                  </div>
                  <div class="team-title">
                    <h2>Kelly Pannitto</h2>
                    <h3>Integrator & Head of Operations</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pop-up-container" id="todd">
          <div className="inner-pop-container team-pop">
            <div class="grid-x grid-padding-x grid-margin-x">
              <div class="large-4 cell">
                <img src="/images/todd-headshot.png" alt="Photo of Todd" />
                <p><a className="button" href="#">Download vCard</a></p>
              </div>
              <div class="large-8 cell  team-pop">
                <div class="title-meta">
                  <h2>Todd Resnick</h2>
                  <h3>Co-Founder President</h3>
                </div>
                <p>Todd is a Co-Founder and President of One Seven. Born and raised in Cleveland, Ohio, Todd’s aspirations were always to find a career where he could impact people’s lives for the better, and create relationships that went far beyond “deal making.” What drew him to the world of financial planning was the fact that even though the markets would go up and down, the relationships forged through that journey would last a lifetime. What motivates Todd to do his best work is to help every client find their own definition of fulfillment—and that is what drew him to being part of the One Seven team. Todd wanted to create an environment that focused on helping people discover what makes them truly happy. Outside the office, Todd and his incredible wife, Niki, currently reside in Solon, Ohio, with their two spectacular kids, Madden and Bryn. Todd is a die-hard Cleveland fan, following everything from sports to music, theatre, and the arts. In addition, Todd and his wife volunteer actively at their children’s schools, and always look forward to traveling together as a family.</p>
              </div>
            </div>
            <div className="photo-gallery">
              <div>
                <img src="https://weareoneseven.com/wp-content/uploads/2017/10/IMG_1909.jpg" alt="" />
              </div>
              <div>
                <img src="https://weareoneseven.com/wp-content/uploads/2017/10/IMG_4915.jpg" alt="" />
              </div>
              <div>
                <img src="https://weareoneseven.com/wp-content/uploads/2017/10/IMG_5397.jpg" alt="" />
              </div>
              <div>
                <img src="https://weareoneseven.com/wp-content/uploads/2017/10/IMG_6010.jpg" alt="" />
              </div>
              <div>
                <img src="https://weareoneseven.com/wp-content/uploads/2017/10/IMG_7382.jpg" alt="" />
              </div>
            </div>
            <i className="lmg-fal lmg-fa-times close-pop" onClick={this.closePop}></i>
          </div>
        </div>
        <div className="pop-up-container" id="ronald">
          <div className="inner-pop-container team-pop">
            <div class="grid-x grid-padding-x grid-margin-x">
              <div class="large-4 cell text-center">
                <img src="/images/ronald-headshot.png" alt="Photo of Ronald" />
                <p><a className="button" href="#">Download vCard</a></p>
              </div>
              <div class="large-8 cell  team-pop">
                <div class="title-meta">
                <h2>Ronald Gross</h2>
                  <h3>Co-Founder, CEO</h3>
                </div>
                <p>A leader at heart, Ron is the President & CEO of MGO Investment Advisors and the Co-Founder of One Seven. After many years of running a successful business, he channeled his entrepreneur skill set into starting One Seven from the ground up. His motivation is to help people achieve their life goals, specifically through wealth management guidance and mentorship. The motivation behind One Seven is to improve the lives of financial advisors by creating a spaceand culture outside the industry norm, where each individual can thrive both personally and professionally. For Ron, it’s about making a positive impact on people’s lives, encouraging self-growth and upward career mobility, all while enjoying the journey. The ultimate reward for Ron is to create long-lasting relationships with people. Some of his favorite hobbies include studying European history, coaching youth wrestling, and volunteering for meaningful organizations—such as North Coast Community Housing for the developmentally disabled. Ron currently livesin Beachwood, Ohio, with his wonderful wife, Shelley, and their three children, Allison, Sammy, and Erica.</p>
              </div>
            </div>
            <div className="photo-gallery">
              <div>
                <img src="https://weareoneseven.com/wp-content/uploads/2017/10/IMG_0078.jpg" alt="" />
              </div>
              <div>
                <img src="https://weareoneseven.com/wp-content/uploads/2017/10/IMG_0105-e1511896707661.jpg" alt="" />
              </div>
              <div>
                <img src="https://weareoneseven.com/wp-content/uploads/2017/10/IMG_0156.jpg" />
              </div>
              <div>
                <img src="https://weareoneseven.com/wp-content/uploads/2017/10/IMG_0190-e1511896676646.jpg" alt="" />
              </div>
              <div>
                <img src="https://weareoneseven.com/wp-content/uploads/2017/10/IMG_0200-e1511896670859.jpg" alt="" />
              </div>
            </div>
            <i className="lmg-fal lmg-fa-times close-pop" onClick={this.closePop}></i>
          </div>
        </div>
        <div className="pop-up-container" id="richard">
          <div className="inner-pop-container team-pop">
            <div class="grid-x grid-padding-x grid-margin-x">
              <div class="large-4 cell">
                <img src="/images/richard-headshot.png" alt="Photo of Richard" />
                <p><a className="button" href="#">Download vCard</a></p>
              </div>
              <div class="large-8 cell  team-pop">
                <div class="title-meta">
                  <h2>Richard Gross</h2>
                  <h3>Co-Founder, CEO</h3>
                </div>
                <p>Rick is the Director of Sales & Marketing of MGO Investment Advisors, and as a Co-Founder of One Seven he oversees the marketing and outreach initiatives for the company. To Rick, his work is not about “wealth management” as much as it is the relationships that are developed as a result. Knowing the client personally is essential in order to deliver top level service, and it’s those interactions that excite Rick to go to work every morning. Rick’s greatest quality is his attention to detail, having spent the first seven years after college serving on active duty in the Navy. Today, Rick currently resides in Solon, Ohio, with his wife, Tera, and their three children. Rick thoroughly enjoys spending time outdoors, one of his passions being tending to his backyard garden—a longstanding family tradition. In addition, Rick loves to golf and staying busy with his three sons, who are all actively involved in local youth sports leagues.</p>
              </div>
            </div>
            <div className="photo-gallery">
              <div>
                <img src="https://weareoneseven.com/wp-content/uploads/2017/10/IMG_0450.jpg" alt="" />
              </div>
              <div>
                <img src="https://weareoneseven.com/wp-content/uploads/2017/10/IMG_1608.jpg" alt="" />
              </div>
              <div>
                <img src="https://weareoneseven.com/wp-content/uploads/2017/10/IMG_1891.jpg" alt="" />
              </div>
              <div>
                <img src="https://weareoneseven.com/wp-content/uploads/2017/10/IMG_1900.jpg" alt="" />
              </div>
              <div>
                <img src="https://weareoneseven.com/wp-content/uploads/2017/10/IMG_1926.jpg" alt="" />
              </div>
            </div>
            <i className="lmg-fal lmg-fa-times close-pop" onClick={this.closePop}></i>
          </div>
        </div>
        <div className="pop-up-container" id="mike">
          <div className="inner-pop-container team-pop">
            <div class="grid-x grid-padding-x grid-margin-x">
              <div class="large-4 cell">
                <img src="/images/mike-headshot.jpg" alt="Photo of Mike" />
                <p><a className="button" href="#">Download vCard</a></p>
              </div>
              <div class="large-8 cell  team-pop">
                <div class="title-meta">
                  <h2>Mike Petramalo</h2>
                  <h3>Head of Advisor and Business Development</h3>
                </div>
                <p>As One Seven’s Head of Advisor and Business Development, Mike Petramalo is passionate about driving business growth by recruiting and coaching top advisors from around the nation. Mike develops them professionally and personally, ensuring their unique talents best meet the needs of our clients. Additionally, due to Mike’s extensive marketing background, he plays a key role in our firm’s branding efforts.</p>

                <p>Mike graduated with honors from Roberts Wesleyan College with a degree in organizational leadership. He spent over 30 years in executive leadership for Fortune 500 companies such as Morgan Stanley, Wells Fargo Advisors, Citicorp, M&T Bank and SunTrust Private Wealth. He has always encouraged a winning culture everywhere he’s planted, and he prides himself on inspiring his colleagues to achieve greatness.</p>

                <p>Coaching has been at the center of Mike’s life since his youth. He played baseball and football at Ithaca College in NY, and became a college baseball coach. Mike’s passion for coaching, leading, and mentoring, led him to found Next Level Advisor Coaching and MFP Advisory Group.</p>

                <p>Mike’s professional accomplishments include being named a “Top 10” and “Top 100” branch manager by On Wall Street numerous times, a “Top 20” program by Bank Investment Consultant Magazine, Morgan Stanley Manager’s Leadership, M&T Bank Hall of Fame, USSSA Hall of Fame, and he’s a Concord Elite recipient.</p>

                <p>In his free time, Mike serves with the Special Olympics (where he was named Special Olympics Individual of the Year), the United Way, and March of Dimes. He is also a former board member of the School of the Holy Childhood. He loves to travel and spend time with his wife, Debbie, and their daughter, Laura. </p>
              </div>
            </div>
            <i className="lmg-fal lmg-fa-times close-pop" onClick={this.closePop}></i>
          </div>
        </div>
        <div className="pop-up-container" id="kelly">
          <div className="inner-pop-container team-pop">
            <div class="grid-x grid-padding-x grid-margin-x">
              <div class="large-4 cell">
                <img src="/images/kelly-headshot.png" alt="Photo of Kelly" />
                <p><a className="button" href="#">Download vCard</a></p>
              </div>
              <div class="large-8 cell  team-pop">
                <div class="title-meta">
                  <h2>Kelly Pannitto</h2>
                  <h3>Integrator & Head of Operations</h3>
                </div>
                <p>As the Head of Operations and EOS Integrator, Kelly Pannitto works hand-in-hand with the President of One Seven to implement business strategies and roll out new visions for the company. With expertise in project management and process improvement, Kelly is skilled at identifying gaps, streamlining processes to mitigate risk, and efficiently resolving issues as they arise. By keeping the team “rowing together in the same direction,” she ensures One Seven advisors have the best possible experience, and in turn provide excellent service to their clients.</p>

                <p>Kelly’s financial services career spans close to three decades. She has held leadership roles at KeyBank and PNC, specializing in Risk Management, Internal Audit, Compliance and Project Management. She is a graduate of Cleveland State University.</p>

                <p>Born and raised in Cleveland, Ohio, Kelly lives in Mayfield Heights with her husband Joe, sons Nicholas and Mitchell, and her mother-in-law who migrated from Italy. She loves taking vacations, working out, spending time with family and friends, and cheering on Cleveland sports teams.</p>
              </div>
            </div>
            <div className="photo-gallery">
              <div>
                <img src="https://weareoneseven.com/wp-content/uploads/2020/02/0657da53a2514b3ebad57a8b4941949d.jpg" alt="" />
              </div>
              <div>
                <img src="https://weareoneseven.com/wp-content/uploads/2020/02/83e82b6dc5d8445281e558cfdf1efd89.jpg" alt="" />
              </div>
              <div>
                <img src="https://weareoneseven.com/wp-content/uploads/2020/02/facetune_01072019180603.jpg" alt="" />
              </div>
              <div>
                <img src="https://weareoneseven.com/wp-content/uploads/2020/02/img_2973_003.jpg" alt="" />
              </div>
              <div>
                <img src="https://weareoneseven.com/wp-content/uploads/2020/02/86470843f8474f459ce51d9db3e2fe2d.jpg" alt="" />
              </div>
            </div>
            <i className="lmg-fal lmg-fa-times close-pop" onClick={this.closePop}></i>
          </div>
        </div>
        <div className="cta-bar text-center">
          <p>
            <NavLink className="cta-button" to="/journey"><span>Continue the Journey</span> <span>7 of 10</span></NavLink>
          </p>
        </div>
      </div>
    );
  }
}
