import React, { Component } from "react";
import Helmet from "react-helmet";
import Basic from '../scripts/basic';
import { NavLink } from 'react-router-dom';

export default class Culture extends Component {
  componentDidMount(){

  }
  render() {
    return (
      <div>
      <div className="transition-overlay"></div>
        <Basic/>
        <Helmet>
          <title>Our Culture  | 7-Tool Advisor by One Seven</title>
          <meta property="og:image" content="https://7tooladvisor.com/images/culture-header.jpg" />
        </Helmet>
        <div className="pageContent">
          <div className="innerContent">
            <div className="wrap">
              <div className="titleContainer">
                <div className="bg-wrapper culture-header"></div>
                <div className="bg-flex-wrapper" />
                <h1>
                  Our Culture
                </h1>
                <i className="arrow-down lmg-fal lmg-fa-chevron-down animated flash"></i>
              </div>
            </div>
          </div>
          <div className="int-tier" id="tier1">
            <div className="wrap pillars-tier">
              <div className="grid-x align-middle">
                <div className="large-5 cell photo-container">
                  <div className="photo-cover" data-ani="slideOutRight"></div>
                  <img src="/images/pillars-background.jpg" alt="" />
                </div>
                <div className="large-7 cell">
                  <div className="tier-content nopadding">
                    <div className="tierTitle">
                      <h2 data-textillate-ani>The work we do is shaped by who we are. Our culture encourages deeper client-advisor relationships that ultimately make all the difference.</h2>
                    </div>
                    <div data-ani="fadeInUp moved-icons">
                      <h3 clas="text-center">Our foundation is built upon:</h3>
                      <div className="grid-x grid-padding-x grid-margin-x text-center">
                        <div className="large-6 cell">
                          <i className="lmg-fal lmg-fa-handshake"></i>
                          <h4>Relationships</h4>
                          <p>Shared experiences and collaboration fuel strong connections between our results-driven team and our clients.</p>
                        </div>
                        <div className="large-6 cell">
                          <i className="lmg-fal lmg-fa-chart-network"></i>
                          <h4>Technology</h4>
                          <p>We’re always sourcing the latest technology solutions to power new investment ideas.</p>
                        </div>
                        <div className="large-6 cell">
                          <i className="lmg-fal lmg-fa-chalkboard-teacher"></i>
                          <h4>Professional Development</h4>
                          <p>From team trainings to one-on-one mentorship, we provide the tools advisors need to meaningfully service clients and grow their businesses.</p>
                        </div>
                        <div className="large-6 cell">
                          <i className="lmg-fal lmg-fa-hands-helping"></i>
                          <h4>Giving Back</h4>
                          <p>We partner with non-profits, providing our team and our clients with an outlet to give back to the community.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="wrap todd-quote-tier">
              <p class="quote" data-ani="fadeInUp">“We are all pushing in the same direction to create a unique business that is focused on helping clients connect their money to things that are meaningful to them. From our office Gratitude Wall to partnering with local non-profits on community events, we are thinking differently.”</p>
              <p class="author" data-ani="fadeInUp">Todd Resnick, Co-Founder & President</p>
            </div>
            <div className="wrap culture-last">
              <h2 data-ani="fadeInUp">There’s more to life than a performance report.<br class="show-for-large-up" /> We believe our clients deserve guidance beyond financial insight.</h2>
              <div className="quote-box">
                <div class="pull-quote" data-ani="fadeIn">
                  <p>One Seven builds relationships that build legacies around the things people truly care about — through a holistic commitment to personal and financial growth.</p>
                </div>
                <p data-ani="fadeIn">Our independent financial advisors spend less time tracking tasks and more time getting to the core of what’s important. We design personalized financial plans through a deep understanding of what really drives our clients — their interests, families, long-term goals, and aspirations for the future. We’re sounding boards for their ambitions, challenges, concerns, and successes. Then we connect their money to what really matters.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="cta-bar text-center">
          <p>
            <NavLink className="cta-button" to="/team"><span>Continue the Journey</span> <span>6 of 10</span></NavLink>
          </p>
        </div>
      </div>
    );
  }
}
