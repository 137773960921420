import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import NavForm from './forms/nav-form.js';

export default class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      popUp: false,
      formFilled: false
    }
  }
  comingSoon = (e) => {
    e.preventDefault();
    alert('Coming Soon!')
  }
  openPop = (e) => {
    let popName = 'startedPop';
    document.getElementById(popName).classList.add('active-pop');
    this.setState({ 
      isMobile: false 
    });
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('mobile-active');
  }
  animateBack = (e) =>{
    // document.getElementById('siteContainer').classList.add('animateBack');
  }
  handleMobile = () => {
    if(document.getElementById('navPop').classList.contains('active-pop')){
      this.setState({ 
        isMobile: false, 
        formFilled: true
      });
      setTimeout(function(){
        document.getElementById('navPop').classList.remove('active-pop');
      }, 750)
    } else {
      this.setState({ 
        isMobile: false, 
      });
      document.getElementById('navPop').classList.add('active-pop');
    }
  }
  render(){
    return(
      <>
        <div className="menu-toggle animated fadeInDown" onClick={this.handleMobile}>
          <div className="one"></div>
          <div className="two"></div>
          <div className="three"></div>
        </div>
        <a className="journey-button animated fadeIndown" onClick={this.openPop}>Get in Touch</a>
        <div className="pop-up-container" id="navPop">
        <i className="lmg-fal lmg-fa-times close-pop" onClick={this.handleMobile}></i>
          <div className="left-side">
            <div className="grid-x">
              <div className="medium-4 large-12 cell nav-container">
                <h2 className="first-nav">What We're About</h2>
                <p><NavLink to="/advisor" onClick={this.handleMobile}>Becoming a 7-Tool Advisor</NavLink><br/>
                <NavLink to="/dream" onClick={this.handleMobile}>Building Your Dream Practice</NavLink><br/>
                <NavLink to="/living-your-best-life" onClick={this.handleMobile}>Living Your Best Life</NavLink></p>
              </div>
              <div className="medium-4 large-12 cell nav-container">
                <h2>Who We Are</h2>
                <p><NavLink to="/story" onClick={this.handleMobile}>Our Story</NavLink><br/>
                <NavLink to="/culture" onClick={this.handleMobile}>Our Culture</NavLink><br/>
                <NavLink to="/team" onClick={this.handleMobile}>Our Leadership Team</NavLink><br/>
                <NavLink to="/blog" onClick={this.handleMobile}>Our Blog</NavLink></p>
              </div>
              <div className="medium-4 large-12 cell nav-container">
                <h2>Start the Journey</h2>
                <p><NavLink to="/journey" onClick={this.handleMobile}>Your Journey to One Seven</NavLink><br/>
                <NavLink to="/testimonials" onClick={this.handleMobile}>What Our Advisors Say</NavLink><br/>
                <a data-pop-name="startedPop" onClick={this.openPop} href="#">Get in Touch</a></p>
              </div>
              <p className="text-center shorten hide-for-large-up"><NavLink to="/book" className="button book-button" onClick={this.handleMobile}>Download Our Book</NavLink></p>
            </div>
          </div>
          <div className="right-side grid-x align-center align-middle show-for-medium">
            <div>
              <NavForm filled={this.state.formFilled} />
              {this.state.formFilled}
              <p className="text-center shorten"><a className="button" href="#" onClick={this.comingSoon}>Advisor Portal</a></p>
              <p className="text-center shorten"><NavLink to="/book" className="button book-button" onClick={this.handleMobile}>Download Our Book</NavLink></p>
            </div>
          </div>
        </div>
      </>
    )
  }
}
