import React, { Component } from 'react';
import axios from 'axios';
import qs from 'querystring';

export default class PopOverForm extends Component {
  state = {
    formFilled: false,
    name: '',
    phone: '',
    email: '',
    website: '',
    address: '',
    why: '',
    what: '',
  }
  componentWillReceiveProps(formProps, state){
    if(formProps.filled === 'Yes'){
      // console.log(formProps.filled)
      this.setState({
        formFilled: false
      })
    } else {
      // console.log(formProps.filled)
      return null
    }
  }
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }
  handleSubmit = (e) => {
    const requestBody = {
      name: this.state.name,
      phone: this.state.phone,
      email: this.state.email,
      website: this.state.website,
      address: this.state.address,
      why: this.state.why,
      what: this.state.what
    }
    const config = {
      headers:{
        'content-type': 'application/x-www-form-urlencoded'
      }
    }
    e.preventDefault();
    axios.post('/form-popover.php', qs.stringify(requestBody), config,).then(response => {
      this.setState({
        formFilled: true
      })
    })
  }
  render() {
    let popOverForm;
    if(!this.state.formFilled){
      popOverForm = <form onSubmit={this.handleSubmit}>
      <div className="grid-x grid-padding-x">
        <div className="large-6 medium-4 cell">
          <p><label className="sendOff" htmlFor="nameOver">Name</label><input id="nameOver" onChange={this.handleChange} type="text" name="name" placeholder="Name"/></p>
        </div>
        <div className="large-6 medium-4 cell">
          <p><label className="sendOff" htmlFor="phoneOver">Phone</label><input id="phoneOver" onChange={this.handleChange} type="text" name="phone" placeholder="Phone" /></p>
        </div>
        <div className="large-6 medium-4 cell">
          <p><label className="sendOff" htmlFor="emailOver">Email</label><input id="emailOver" onChange={this.handleChange} type="text" name="email" placeholder="Email" /></p>
        </div>
        <div className="large-6 medium-6 cell">
          <p><label className="sendOff" htmlFor="websiteOver">Website</label><input id="websiteOver" onChange={this.handleChange} type="text" name="website" placeholder="Website (if applicable" /></p>
        </div>
        <div className="large-12 medium-6 cell">
          <p><label className="sendOff" htmlFor="addressOver">Address</label><input id="addressOver" onChange={this.handleChange} type="text" name="address"placeholder="Address" /></p>
        </div>
        <div className="large-6 medium-12 cell">
          <p><label className="sendOff" htmlFor="consideringOver">Why are you considering leaving where you are now?</label><textarea id="consideringOver" onChange={this.handleChange} name="why" placeholder="Why are you considering leaving where you are now?"></textarea></p>
        </div>
        <div className="large-6 medium-12 cell">
          <p><label className="sendOff" htmlFor="interestsOver">What interests you about One Seven?</label><textarea id="interestsOver" onChange={this.handleChange} name="what" placeholder="What interests you about One Seven?"></textarea></p>
        </div>
        <div className="large-12 cell">
          <p><input type="submit" className="button" value="Submit" /></p>
        </div>
      </div>
    </form>
    } else {
      popOverForm = <p>Thank you for reaching out!  We will be in touch as soon as possible.</p>
    }
    return (
      <>
         <h2>Join the One Seven Life</h2>
        <p>If you or your team would like to grow your practice with One Seven, fill out the short application below and we’ll be in touch.</p>
        {popOverForm}
      </>
    )
  }
}