import React, { Component } from "react";
import Helmet from "react-helmet";
import { NavLink } from 'react-router-dom';
import Basic from '../scripts/basic';

export default class Journey extends Component {
  render() {
    return (
      <div>
      <div className="transition-overlay"></div>
        <Basic/>
        <Helmet>
          <title>Living Your Best Life  | 7-Tool Advisor by One Seven</title>
          <meta property="og:image" content="https://7tooladvisor.com/images/istockphoto-537459698-1024x1024.jpg" />
        </Helmet>
        <div className="pageContent">
          <div className="innerContent">
            <div className="wrap">
              <div className="titleContainer">
                <div className="bg-wrapper journey"></div>
                <div className="bg-flex-wrapper" dangerouslySetInnerHTML={{__html: this.props.bg}} />
                <h1>
                Living Your Best Life
                </h1>
                <i className="arrow-down lmg-fal lmg-fa-chevron-down animated flash">
                </i>
              </div>
              <div className="int-tier tier1" id="tier1">
                <div className="wrap">
                  <div className="grid-x align-middle">
                    <div className="large-7 cell photo-container">
                      <div className="photo-cover" data-ani="slideOutRight"></div>
                      <img src="/images/30571930.jpg" alt="" />
                    </div>
                    <div className="large-5 cell">
                      <div className="tier-content">
                        <div className="tierTitle">
                          <h2 data-textillate-ani>
                            Backpacks are symbolic here at One Seven.
                          </h2>
                        </div>
                        <div data-ani="fadeInUp">
                          <p>They remind us of the past when the whole world was ours for the taking. They make us dream about the future and the adventures that are still to come. Most of all, they represent what One Seven is all about: Experiencing life on the highest level.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="int-tier tier2">
                <div className="wrap">
                  <div className="inner-wrap">
                    <h2 data-textillate-ani>Living your best life means enjoying what you do for a living.</h2>
                    <div className="indent-text" data-ani="fadeInUp">
                      <p>That’s why our goal at One Seven is to remove your pain points and allow you to spend more time doing what you love and making a difference in your clients’ lives. If we can help you get more out of your own life, you’ll be able to help your clients get more out of theirs, and there’s nothing that motivates us more than seeing our advisors’ lives change and watching them pay it forward with the people they serve.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="int-tier tier3">
                <div className="wrap">
                  <div className="grid-x grid-padding-x">
                    <div className="large-6 cell">
                      <div className="inner-cell">
                        <h2 data-textillate-ani>Our Mission</h2>
                        <p data-ani="fadeInUp">To help you design the business of your dreams<br className="show-for-large" /> and spend more time doing what you love</p>
                      </div>
                    </div>
                    <div className="large-6 cell">
                      <div className="inner-cell">
                        <h2 data-textillate-ani>Our Passion</h2>
                        <p data-ani="fadeInUp">To create life changing experiences and<br className="show-for-large" /> opportunities for you and your clients</p>
                      </div>
                    </div>
                    <div className="large-6 cell">
                      <div className="inner-cell">
                        <h2 data-textillate-ani>Our Promise</h2>
                        <p data-ani="fadeInUp">To always have your back and to provide you with<br className="show-for-large" /> the tools you need to make your life easier</p>
                      </div>
                    </div>
                    <div className="large-6 cell">
                      <div className="inner-cell">
                        <h2 data-textillate-ani>Our Vision</h2>
                        <p data-ani="fadeInUp">To impact millions of lives through a ripple<br className="show-for-large" /> effect that starts with us impacting your life</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cta-bar text-center">
          <p>
            <NavLink className="cta-button" to="/story"><span>Continue the Journey</span> <span>4 of 10</span></NavLink>
          </p>
        </div>
      </div>
    );
  }
}
