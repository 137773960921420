import React, { Component } from "react";
import Helmet from "react-helmet";
import Basic from '../scripts/basic';
import { NavLink } from 'react-router-dom';


export default class Dream extends Component {
  state = {
    popUp: false
  }
  openPop = (e) => {
    let popName = e.target.dataset.popName;
    document.getElementById(popName).classList.add('active-pop');
    document.getElementById('siteContainer').classList.remove('animateBack');
    document.getElementById('siteContainer').classList.add('animateDown');
  }
  animateBack = (e) =>{
    document.getElementById('siteContainer').classList.add('animateBack');
  }
  closePop = (e) => {
    document.getElementsByClassName('active-pop')[0].classList.remove('active-pop');
    document.getElementById('siteContainer').classList.add('animateBack');
  }
  componentDidMount(){
    console.log(this.props.bg);
  }
  render() {
    return (
      <div id="siteContainer">
      <div className="transition-overlay"></div>
        <Basic/>
        <Helmet>
          <title>Building Your Dream Practice | 7-Tool Advisor by One Seven</title>
          <meta property="og:image" content="https://7tooladvisor.com/images/dream-header.jpg" />
        </Helmet>
        <div className="pageContent">
          <div className="innerContent">
            <div className="wrap">
              <div className="titleContainer">
                <div className="bg-wrapper dream"></div>
                <div className="bg-flex-wrapper" dangerouslySetInnerHTML={{__html: this.props.bg}} />
                <h1>
                Building Your Dream Practice
                </h1>
                <i className="arrow-down lmg-fal lmg-fa-chevron-down animated flash">
                </i>
              </div>
            </div>
            <div className="int-tier dream-tier1" id="tier1">
              <div className="wrap">
                <h2>What does your dream<br className="show-for-large" /> practice look like? </h2>
                <div className="grid-x">
                  <div className="large-5 cell" data-ani="fadeInUp">
                  </div>
                  <div className="large-7 cell" data-ani="fadeInUp">
                    <p>How would you spend your time and energy? Where would you be? Who would you be with? What obstacles would no longer be in your way?  What resources would you have at your disposal?</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="int-tier dream-tier2">
              <div className="wrap">
                <div className="inner-wrap">
                  <div className="indent-text text-center">
                  <p>No matter what the ideal financial practice looks like for you, One Seven can help you turn your dream into reality by providing you with the freedom to create the kind of business you want, and giving you the right <br className="show-for-large" />equipment to do your job.</p>
                  <p className="small-text"> Click any icon to learn more.</p>
                    <div className="grid-x align-center align-middle">
                      <div className="large-4 medium-6 cell">
                        <div className="inner-cell top-left" data-class="active-bag">
                          <a className="overlay-link" onClick={this.openPop} data-pop-name="practiceSupport"></a>
                          <i className="lmg-fal lmg-fa-laptop"></i>
                          <h2>Practice Support</h2>
                        </div>
                      </div>
                      <div className="large-4 medium-6 cell">
                        <div className="inner-cell top-center" data-class="active-bag">
                        <a className="overlay-link" onClick={this.openPop} data-pop-name="advantage360"></a>
                          <i className="lmg-fal lmg-fa-user-headset"></i>
                          <h2>Advantage 360</h2>
                        </div>
                      </div>
                      <div className="large-4 medium-6 cell">
                        <div className="inner-cell top-right" data-class="active-bag">
                        <a className="overlay-link" onClick={this.openPop} data-pop-name="insurance"></a>
                          <i className="lmg-fal lmg-fa-comment-alt-dots"></i>
                          <h2>Insurance</h2>
                        </div>
                      </div>
                      <div className="large-4 medium-6 cell">
                        <div className="inner-cell center-left" data-class="active-bag">
                        <a className="overlay-link" onClick={this.openPop} data-pop-name="ppDevelopment"></a>
                          <i className="lmg-fal lmg-fa-users-cog"></i>
                          <h2>Personal/Professional Development</h2>
                        </div>
                      </div>
                      <div className="large-4 cell show-for-large">
                        <div className="inner-cell backpack-cell" data-class="active-bag">
                          <img src="/images/backpack-5.png" alt="Backpack icon" />
                        </div>
                      </div>
                      <div className="large-4  medium-6 cell">
                        <div className="inner-cell center-right" data-class="active-bag">
                        <a className="overlay-link" onClick={this.openPop} data-pop-name="investmentManagement"></a>
                          <i className="lmg-fal lmg-fa-shield-alt"></i>
                          <h2>Investment Management</h2>
                        </div>
                      </div>
                      <div className="large-4  medium-6 cell">
                        <div className="inner-cell bottom-left" data-class="active-bag">
                        <a className="overlay-link" onClick={this.openPop} data-pop-name="financialPlanning"></a>
                          <i className="lmg-fal lmg-fa-clipboard-list"></i>
                          <h2>Financial Planning</h2>
                        </div>
                      </div>
                      <div className="large-4  medium-6 cell">
                        <div className="inner-cell bottom-right" data-class="active-bag">
                        <a className="overlay-link" onClick={this.openPop} data-pop-name="marketing"></a>
                          <i className="lmg-fal lmg-fa-laptop"></i>
                          <h2>Marketing/Business Development</h2>
                        </div>
                      </div>
                      {/* <div className="large-4 cell" data-ani="fadeInUp">
                        <div className="inner-cell">
                        <a className="overlay-link" onClick={this.openPop} data-pop-name="onesevenPop"></a>
                          <i className="lmg-fal lmg-fa-map-marked-alt"></i>
                          <h2>One Seven Life Map</h2>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="int-tier dream-tier4">
              <div className="wrap" data-ani="fadeInUp">
                <p>Utilizing our equipment takes a lot of the behind-the-scenes work out of your hands, allowing things to run more efficiently so you can do what you do best and enjoy it more.  The end result is the ability to spend more time doing the things you love with the people you care about, and we call that living your best life.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="pop-up-container" id="practiceSupport">
          <div className="inner-pop-container text-center">
            <h2>Practice Support</h2>
            <p>We founded One Seven to be a platform that supports advisors in reaching their full potential, both professionally and personally.  When you join One Seven, you get all the support you need from day one as we help you strategize your transition with our team and make your launch as successful as possible.  We then surround you with an experience that gives you everything you need to manage and grow your practice, including finance and billing, legal and compliance, technology, operations, marketing, and more. </p>
            <i className="lmg-fal lmg-fa-times close-pop" onClick={this.closePop}></i>
          </div>
        </div>
        <div className="pop-up-container" id="advantage360">
          <div className="inner-pop-container text-center">
            <h2>Advantage 360</h2>
            <p>This is a fully developed 401(k) platform that helps you find opportunities and win more business in the qualified plan space.  The team at MGO has spent decades perfecting the administration of 401(k) and profit sharing plans and provides a unique experience for the advisor, the company or plan sponsor, and the employee.  From plan design to education, investment management to annual filings, everything is done through this unique process that has over 180 plans and $1B in assets</p>
            <i className="lmg-fal lmg-fa-times close-pop" onClick={this.closePop}></i>
          </div>
        </div>
        <div className="pop-up-container" id="marketingPop">
          <div className="inner-pop-container text-center">
            <h2>Marketing Consulting</h2>
            <p>Labore eiusmod consequat esse ipsum consectetur Lorem laboris. Officia reprehenderit eu ut nisi incididunt incididunt laborum nostrud. Laborum labore id tempor incididunt in ad amet cillum culpa elit nisi duis. Consequat aliquip eiusmod commodo veniam duis cupidatat. Amet laboris cillum magna incididunt aute magna. Consectetur quis commodo anim tempor pariatur adipisicing irure commodo excepteur pariatur non minim. Fugiat elit amet ad enim ipsum nulla proident id irure nisi.</p>
            <i className="lmg-fal lmg-fa-times close-pop" onClick={this.closePop}></i>
          </div>
        </div>
        <div className="pop-up-container" id="insurance">
          <div className="inner-pop-container text-center">
            <h2>Insurance</h2>
            <p>Insurance is a critical component of financial planning and financial security for families across the world. While your clients are building wealth, it’s equally important to ensure that it’s protected. Whether your goal is safeguarding the assets and inheritance of a generational wealth transfer or bringing peace to a growing family, One Seven equips you with the tools and support to help families and individuals use insurance to its fullest potential. Our team works with you to determine the best route to help your clients, providing support and expertise to protect their dreams.</p>
            <i className="lmg-fal lmg-fa-times close-pop" onClick={this.closePop}></i>
          </div>
        </div>
        <div className="pop-up-container" id="ppDevelopment">
          <div className="inner-pop-container text-center">
            <h2>Personal/Professional Development</h2>
            <p>As advisors, growing ourselves is where the greatest opportunity lies.  We believe that out of all the equipment we provide, this is the element that can make the biggest impact.  This is where we help 7-Tool Advisors become who they are, as we surround them with personalized coaching and daily support to grow the business of their dreams.  In addition, the advisor experience features self-development modules, created in tandem with the 7 tools, along with resources to help them provide an even deeper and more meaningful experience for their clients.  From philanthropic planning to client concierge needs, self-development can mean so many things and help in so many ways.</p>
            <i className="lmg-fal lmg-fa-times close-pop" onClick={this.closePop}></i>
          </div>
        </div>
        <div className="pop-up-container" id="investmentManagement">
          <div className="inner-pop-container text-center">
            <h2>Investment Management</h2>
            <p>One Seven believes that spending time with clients, building your dream business, and tackling personal goals are valuable uses of your time. Constructing and executing the perfect portfolio is a necessary task, but in a world of dynamic investment opportunities, it can become an overwhelming endeavor that consumes your time. Through our partnership with SMArtX, One Seven’s OCIO and Investment Committee creates and manages diverse model portfolios, so you have more time for your clients. In addition to the model portfolio, you’ll receive quarterly commentary and access to investment strategists for questions and suggestions. Our investment management tool offers portfolios for every level of investment and risk, so you no longer have to invest the best of your time to achieve the best results.</p>
            <i className="lmg-fal lmg-fa-times close-pop" onClick={this.closePop}></i>
          </div>
        </div>
        <div className="pop-up-container" id="financialPlanning">
          <div className="inner-pop-container text-center">
            <h2>Financial Planning</h2>
            <p>Financial planning is the cornerstone of our business and value to clients, and we want to make it an even more impactful experience.  The vision, still to come, is to have a team that will help advisors run plans, unearth opportunities, and provide services that help their clients build and achieve goals.  This frees the advisor to help their clients and also focus on business and personal development.  The family office group, Park City Family Office, is also an internal resource to help advisors connect and work with families and individuals with $20M and up.</p>
            <i className="lmg-fal lmg-fa-times close-pop" onClick={this.closePop}></i>
          </div>
        </div>
        <div className="pop-up-container" id="marketing">
          <div className="inner-pop-container text-center">
            <h2>Marketing/Business Development</h2>
            <p>Our core value, “always be growing” has never been more significant. One Seven believes in growth for you and your practice, which is why we’re committed to helping you find new and exciting paths for business development. As the population ages, competition increases, and fees are compressed, it’s important to identify new avenues for growth with your next generation of clients. One Seven offers multiple opportunities for business development and marketing including seminars, new client initiatives, and our upcoming Elite Advisor Lead Generation program. You’re in the business of helping your clients succeed, and with our marketing and business development tools, we’ll make sure you succeed, too. </p>
            <i className="lmg-fal lmg-fa-times close-pop" onClick={this.closePop}></i>
          </div>
        </div>
        <div className="pop-up-container" id="onesevenPop">
          <div className="inner-pop-container text-center">
            <h2>One Seven Life Map</h2>
            <p>The final piece of equipment in the backpack is the one thing you provide: The One Seven Life Map. During your onboarding process, we will go through an exercise where we’ll set life goals in seven key areas that greatly impact what you accomplish in life and how much enjoyment you get. They are: Family, Community, Physical, Spiritual, Finances, Career, and Mental. Thinking through each one and documenting what’s important to you is a critical step that must be taken in order to live your best life.</p>
            <i className="lmg-fal lmg-fa-times close-pop" onClick={this.closePop}></i>
          </div>
        </div>
        <div className="cta-bar text-center">
          <p>
            <NavLink className="cta-button" to="/living-your-best-life"><span>Continue the Journey</span> <span>3 of 10</span></NavLink>
          </p>
        </div>
      </div>
    );
  }
}
