import React, { Component } from "react";
import Slider from "react-slick";

export default class Testimonials extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      autoplay: true,
      autoplaySpeed: 1500
    };
    return (
      <div>
        <Slider {...settings}>
          <div class="slideContainer">
            <p>&ldquo;Ex ex exercitation cupidatat excepteur officia reprehenderit nisi ea. Tempor ut et duis fugiat velit nisi. Minim voluptate in sint dolore aliquip exercitation deserunt esse quis pariatur aute fugiat reprehenderit incididunt.&rdquo;</p>
          </div>
          <div class="slideContainer">
            <p>&ldquo;Laboris exercitation consequat velit aliqua. Proident occaecat eu id mollit veniam ullamco culpa. Fugiat nostrud commodo sit sint excepteur elit labore consectetur consequat incididunt. Irure velit quis ex ea minim eiusmod. Ea officia aliqua esse eiusmod excepteur aliqua duis consectetur nostrud aliquip ullamco dolor fugiat exercitation. Tempor Lorem adipisicing duis adipisicing. Aute nostrud cupidatat aliqua voluptate sint nisi exercitation consequat ad sit esse irure cillum duis.&rdquo;</p>
          </div>
          <div class="slideContainer">
            <p>&ldquo;Dolore aliqua aliquip occaecat incididunt eiusmod labore excepteur esse mollit consequat proident eu anim dolor. Labore pariatur velit cupidatat aute nulla ut occaecat nostrud. Quis id duis fugiat qui et excepteur ut nulla irure proident voluptate aliqua nulla sit.&rdquo;</p>
          </div>
        </Slider>
      </div>
    );
  }
}
