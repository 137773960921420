import React, { Component } from "react";
import Helmet from "react-helmet";
import Basic from '../scripts/basic';

export default class Expertise extends Component {
  componentDidMount(){

  }
  render() {
    return (
      <div>
      <div className="transition-overlay"></div>
        <Basic/>
        <Helmet>
          <title>Services & Expertise | 7-Tool Advisor by One Seven</title>
          <meta property="og:image" content="https://7tooladvisor.com/images/expertise-header.jpg" />
        </Helmet>
        <div className="pageContent">
          <div className="innerContent">
            <div className="wrap">
              <div className="titleContainer">
                <div className="bg-wrapper expertise"></div>
                <div className="bg-flex-wrapper" dangerouslySetInnerHTML={{__html: this.props.bg}} />
                <h1>
                  Services & Expertise
                </h1>
                <i className="arrow-down lmg-fal lmg-fa-chevron-down animated flash"></i>
              </div>
              <div className="int-tier tier3" id="tier1">
                <div className="wrap">
                  <div className="grid-x grid-padding-x">
                    <div className="large-12 cell" data-ani="fadeInUp">
                      <div className="inner-cell">
                        <p className="number" data-ani="fadeIn">01</p>
                        <h2 data-textillate-ani>Financial Planning & Wealth Management</h2>
                        <p data-ani="fadeInUp">From buying a home to saving for retirement, One Seven advisors create personalized financial plans that evolve with our clients’ lives. Our planning process gets us to the core of where you are now and where you want to go-allowing us to determine the most strategic ways to save, grow, and spend your wealth. We provide ongoing guidance to keep your unique plan on track every step of the way.</p>
                      </div>
                    </div>
                    <div className="large-12 cell" data-ani="fadeInUp">
                      <div className="inner-cell text-right">
                        <p className="number" data-ani="fadeIn">02</p>
                        <h2 data-textillate-ani>Retirement Planning </h2>
                        <p data-ani="fadeInUp">When? Where? How? When it comes to planning for retirement, the answers to those three big questions differ for everyone. Together, we’ll create a roadmap for your next chapter, prepare your finances for life’s big events, and set goals that drive long-term success.</p>
                      </div>
                    </div>
                    <div className="large-12 cell" data-ani="fadeInUp">
                      <div className="inner-cell">
                        <p className="number" data-ani="fadeIn">03</p>
                        <h2 data-textillate-ani>Estate & Legacy Planning</h2>
                        <p data-ani="fadeInUp">We work with clients to shape legacies around things they truly care about, while building wealth across generations. From business succession planning to understanding inheritance, our team creates a unique environment for families to have important conversations about their assets, shared values, and vision for the future.</p>
                      </div>
                    </div>
                    <div className="large-12 cell" data-ani="fadeInUp">
                      <div className="inner-cell text-right">
                        <p className="number" data-ani="fadeIn">04</p>
                        <h2 data-textillate-ani>Philanthropic Planning </h2>
                        <p data-ani="fadeInUp">Giving back helps our clients go beyond the balance sheet to create a legacy defined by purpose. Whether it’s investing time, money, or expertise, our advisors identify philanthropic opportunities that align with your passions to help you make a meaningful impact in the world.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
