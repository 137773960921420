import React, { Component } from "react";
import Helmet from "react-helmet";
import Basic from '../scripts/basic';
import { NavLink } from 'react-router-dom';

export default class LifeMap extends Component {
  constructor(props){
    super(props)
    this.state = {
      family: '',
      community: '',
      physical: '',
      spiritual: '',
      financial: '',
      career: '',
      mental: ''
    };
    this.handleInputChange = this.handleInputChange.bind(this)
  }
  handleInputChange (e) {
    const { name, value } = e.target
    this.setState({
      [name]: value
    })
    console.log(this.state.family);
  }
  handleForm(e){
    e.preventDefault();
  }
  componentDidMount(){

  }
  render() {
    return (
      <div>
      <div className="transition-overlay"></div>
        <Basic/>
        <Helmet>
          <title>One Seven Life Map  | 7-Tool Advisor by One Seven</title>
          <meta property="og:image" content="https://7tooladvisor.com/images/culture-header.jpg" />
        </Helmet>
        <div className="pageContent">
          <div className="innerContent">
            <div className="wrap">
              <div className="titleContainer">
                <div className="bg-wrapper culture-header"></div>
                <div className="bg-flex-wrapper" dangerouslySetInnerHTML={{__html: this.props.bg}} />
                <h1>
                One Seven Life Map
                </h1>
                <i className="arrow-down lmg-fal lmg-fa-chevron-down animated flash"></i>
              </div>
            </div>
          </div>
          <div className="int-tier" id="tier1">
          <div className="wrap pillars-tier">
              <div className="grid-x align-middle">
                <div className="large-6 cell photo-container">
                  <div className="photo-cover" data-ani="slideOutRight"></div>
                  <img src="/images/pillars-background.jpg" alt="" />
                </div>
                <div className="large-6 cell">
                  <div className="tier-content">
                    <div className="tierTitle">
                    <h2 data-textillate-ani>One Seven was founded on the Seven Pillars of Wellness — finding balance in all areas of life.</h2>
                    </div>
                    <div data-ani="fadeInUp">
                      <p>We’re motivated by the full picture of well-being. The most rewarding part of our work is having a genuine connection to our clients and guiding them through all facets of life — purchasing a new home, caring for parents, sending kids to college, changing career paths, and someday retiring. When you’re at someone’s side as they accomplish their dreams, it forges a relationship that will last a lifetime.</p>
                      <p>As advisors, we believe that it’s just as important for us to crystallize our own goals and doing so in seven key areas creates a life map that will guide us on the journey ahead.</p>
                      <ul className="iconsList">
                        <li>
                          <i className="lmg-fal lmg-fa-users"></i>
                          <h3>Family</h3>
                        </li>
                        <li>
                          <i className="lmg-fal lmg-fa-hands-heart"></i>
                          <h3>Community</h3>
                        </li>
                        <li>
                          <i className="lmg-fal lmg-fa-running"></i>
                          <h3>Physical</h3>
                        </li>
                        <li>
                          <i className="lmg-fal lmg-fa-praying-hands"></i>
                          <h3>Spiritual</h3>
                        </li>
                        <li>
                          <i className="lmg-fal lmg-fa-chart-scatter"></i>
                          <h3>Finances</h3>
                        </li>
                        <li>
                          <i className="lmg-fal lmg-fa-building"></i>
                          <h3>Career</h3>
                        </li>
                        <li>
                          <i className="lmg-fal lmg-fa-brain"></i>
                          <h3>Mental</h3>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="wrap">
              <p class="leadin-text" data-ani="fadeInUp">Fill out the below fields to create your own One Seven LifeMap&trade;.</p>
              <div className="formContainer">
                <form action="/pdfpage" onSubmit={this.handleForm}>
                  <div className="grid-x grid-padding-x">
                    <div className="large-6 cell">
                      <p><textarea value={this.state.value} onChange={this.handleInputChange} name="family" placeholder="Family Goals"></textarea></p>
                    </div>
                    <div className="large-6 cell">
                      <p><textarea value={this.state.value} onChange={this.handleInputChange} name="community" placeholder="Community Goals"></textarea></p>
                    </div>
                    <div className="large-6 cell">
                      <p><textarea value={this.state.value} onChange={this.handleInputChange} name="physicial" placeholder="Physical Goals"></textarea></p>
                    </div>
                    <div className="large-6 cell">
                      <p><textarea value={this.state.value} onChange={this.handleInputChange} name="spiritual" placeholder="Spiritual Goals"></textarea></p>
                    </div>
                    <div className="large-6 cell">
                      <p><textarea value={this.state.value} onChange={this.handleInputChange} name="financial" placeholder="Financial Goals"></textarea></p>
                    </div>
                    <div className="large-6 cell">
                      <p><textarea value={this.state.value} onChange={this.handleInputChange} name="career" placeholder="Career Goals"></textarea></p>
                    </div>
                    <div className="large-6 cell">
                      <p><textarea value={this.state.value} onChange={this.handleInputChange} name="mental" placeholder="Mental Goals"></textarea></p>
                    </div>
                    <div className="large-12 cell">
                      <p><button>Get My Life Map &nbsp;&nbsp;<i class="lmg-fal lmg-fa-long-arrow-right"></i></button></p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="cta-bar text-center">
          <p>
            <NavLink className="cta-button" to="/team"><span>Continue the Journey</span></NavLink>
          </p>
        </div> */}
      </div>
    );
  }
}
