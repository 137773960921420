import React, { Component } from 'react';
// import LazyLoad from 'react-lazyload';
import { NavLink } from 'react-router-dom';
import Helmet from 'react-helmet';
// import axios from 'axios';
import jQuery from 'jquery';
import $ from 'jquery';
// import { Tween, Timeline, SplitLetters, Controls  } from 'react-gsap';

export default class Homepage extends Component {
  componentDidMount(){
    window.jQuery = jQuery;
    require('letteringjs');
    require('textillate');
    $('.overlayText h2 span:first-child').textillate({ 
      in: { 
        effect: 'fadeInDown',
        shuffle: true,
        callback: function(){
          document.getElementsByClassName('secondLine')[0].classList.add('fadeInUp');
          // document.getElementsByClassName('journeyButton')[0].classList.add('fadeInUp');
        }
      }
    });
  }
    render() {
        return (
          <div>
            <Helmet>
              <title>7-Tool Advisor by One Seven</title>
              <meta property="og:image" content="https://7tooladvisor.com/images/87279381.jpg" />
            </Helmet>
            <div className="transition-overlay"></div>
            <div className="pageContent">
              <div className="hero">
                <div className="overlayText">
                  <h2>
                    <span>It&rsquo;s not just a job.</span> <span className="secondLine animated">It&rsquo;s a journey.</span>
                  </h2>
                  <p className="subHeadline animated fadeInUp">Providing the solutions you need to build the practice of your dreams.</p>
                </div>
              </div>
            </div>
            <div className="featuredIn">
              <h2>Featured In</h2>
              <ul>
                <li><NavLink to="/featured-in"><img src="/images/financial-planning-logo.png" alt="" /></NavLink></li>
                <li><NavLink to="/featured-in"><img src="/images/insurance-news-logo.png" alt="" /></NavLink></li>
                <li><NavLink to="/featured-in"><img src="/images/wealth-adviser-logo.png" alt="" /></NavLink></li>
              </ul>
            </div>
            <div className="cta-bar text-center">
              <p>
                <NavLink className="cta-button" to="/advisor"><span>Start the Journey</span> <span>1 of 10</span></NavLink>
              </p>
            </div>
          </div>
        )
    }
}