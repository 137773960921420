import React, { Component } from "react";
import Helmet from "react-helmet";
import Basic from '../scripts/basic';
import { NavLink } from 'react-router-dom';

export default class Story extends Component {
  componentDidMount(){

  }
  render() {
    return (
      <div>
      <div className="transition-overlay"></div>
        <Basic/>
        <Helmet>
          <title>Our Story | 7-Tool Advisor by One Seven</title>
          <meta property="og:image" content="https://7tooladvisor.com/images/istockphoto-1137111484-1024x1024.jpg" />
        </Helmet>
        <div className="pageContent our-story">
          <div className="innerContent">
            <div className="wrap">
              <div className="titleContainer">
                <div className="bg-wrapper story"></div>
                <div className="bg-flex-wrapper" dangerouslySetInnerHTML={{__html: this.props.bg}} />
                <h1>
                  Our Story
                </h1>
                <i className="arrow-down lmg-fal lmg-fa-chevron-down animated flash"></i>
              </div>
            </div>
          </div>
          <div className="int-tier">
            <div className="wrap">
              <div className="grid-x grid-padding-x align-middle">
                <div className="large-6 cell" data-ani="fadeInUp">
                  <div className="video-wrapper">
                    <iframe title="Why We Formed One Seven Video" src="https://www.youtube.com/embed/bfB6E4LZ6tA?rel=0" allow="autoplay; encrypted-media" allowfullscreen=""></iframe>
                  </div>
                </div>
                <div className="large-6 cell videoIntro">
                  <h2>Why We Formed One Seven</h2>
                  <p>Todd discusses how the creation of One Seven allowed us to develop our philosophies and design a better client experience.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="int-tier">
            <div className="wrap">
              <div className="grid-x grid-padding-x align-middle">
              <div className="large-order-2 large-6 cell" data-ani="fadeInUp">
                  <div className="video-wrapper">
                    <iframe title="How We Conquered Change When Building One Seven Video" width="602" height="339" src="https://www.youtube.com/embed/unA3uUf9b2Q" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                </div>
                <div className="large-order-1 large-6 cell videoIntro">
                  <h2>How We Conquered Change When Building One Seven</h2>
                  <p>Co-Founder and President Todd reminisces on the challenges and unknowns of forming One Seven.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="int-tier" id="tier1">
            <div className="wrap">
              <div className="grid-x grid-padding-x align-middle">
                <div className="large-6 cell" data-ani="fadeInUp">
                  <div className="video-wrapper">
                    <iframe title="Going Beyond the Balance Sheet Video" width="602" height="339" src="https://www.youtube.com/embed/LWFF7KAPbdI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                </div>
                <div className="large-6 cell videoIntro">
                  <h2>Going Beyond the Balance Sheet</h2>
                  <p>One of our founders talks about why we created One Seven with the client in mind, in order to provide the best service and direction for our clients.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="int-tier">
            <div className="wrap">
              <div className="grid-x grid-padding-x align-middle">
                <div className="large-order-2 large-6 cell" data-ani="fadeInUp">
                  <div className="video-wrapper">
                    <iframe title="My Journey to Independence" class="vidyard_iframe" src="//play.vidyard.com/nmSEJz3zS7e3ANWPxQkbjg.html?" width="960" height="540" scrolling="no" frameborder="0" allowtransparency="true" allowfullscreen></iframe>
                  </div>
                </div>
                <div className="large-order-1 large-6 cell videoIntro">
                  <h2>My Journey to Independence</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="int-tier values-tier text-center">
            <div class="wrap">
              <div class="inner">
                <h2 data-ani="fadeInUp">Our Core Values</h2>
                <div className="grid-x grid-padding-x grid-margin-x">
                  <div className="large-3 medium-6 cell" data-ani="fadeInUp">
                    <i className="lmg-fal lmg-fa-seedling"></i>
                    <h3>Always Be Growing</h3>
                  </div>
                  <div className="large-3 medium-6 cell" data-ani="fadeInUp">
                    <i className="lmg-fal lmg-fa-thumbs-up"></i>
                    <h3>Own Success</h3>
                  </div>
                  <div className="large-3 medium-6 cell" data-ani="fadeInUp">
                    <i className="lmg-fal lmg-fa-handshake"></i>
                    <h3>Client First</h3>
                  </div>
                  <div className="large-3 medium-6 cell" data-ani="fadeInUp">
                    <i className="lmg-fal lmg-fa-ban"></i>
                    <h3>No Assholes</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cta-bar text-center">
          <p>
            <NavLink className="cta-button" to="/culture"><span>Continue the Journey</span> <span>5 of 10</span></NavLink>
          </p>
        </div>
      </div>
    );
  }
}
