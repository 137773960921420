import React, { Component } from "react";
import Helmet from "react-helmet";
import jQuery from 'jquery';
import $ from 'jquery';
import Animate from 'animate.css';
import * as ScrollMagic from 'scrollmagic';
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import {gsap, TimelineMax, TweenMax, CSSPlugin, Linear, Power4, Power1 } from 'gsap';
import Basic from '../scripts/basic';
import { NavLink } from 'react-router-dom';
export default class Advisor extends Component {
  state = {
    backg: ''
  }
  componentDidMount() {
    const script  = document.createElement("script");
    script.src = "https://www.buzzsprout.com/844219/4795145-from-wirehouse-to-independence-an-ria-s-insight-on-culture-and-communication.js?container_id=buzzsprout-player-4795145&player=small";
    script.async = true;
    document.body.appendChild(script);
  }
  render() {
    return (
      <div id="sevenTool">
        <div className="transition-overlay"></div>
        <Basic/>
        <Helmet title="Becoming a 7-Tool Advisor | 7-Tool Advisor by One Seven" />
        <Helmet>
          <title>Becoming a 7-Tool Advisor  | 7-Tool Advisor by One Seven</title>
          <meta property="og:image" content="https://7tooldavisor.com/images/istockphoto-639000086-1024x1024.jpg" />
        </Helmet>
        <div className="pageContent">
          <div className="innerContent">
            <div className="wrap">
              <div className="titleContainer">
                <div className="bg-wrapper advisor"></div>
                <div className="bg-flex-wrapper" dangerouslySetInnerHTML={{__html: this.props.bg}} />
                <h1>
                  Becoming a 7-Tool Advisor
                </h1>
                <i className="arrow-down lmg-fal lmg-fa-chevron-down animated flash">
                </i>
              </div>
              <div className="int-tier advisor-tier1" id="tier1">
                <div className="wrap">
                <div className="grid-x align-middle">
                    <div className="large-5 cell photo-container">
                      <div className="photo-cover" data-ani="slideOutRight"></div>
                      <img src="/images/38336786.jpg" alt="" />
                    </div>
                    <div className="large-7 cell">
                      <div className="tier-content">
                        <div className="tierTitle">
                          <h2 data-textillate-ani>
                            What is a 7-Tool Advisor?
                          </h2>
                        </div>
                        <div data-ani="fadeInUp">
                          <p className="specialText alt">A 5-Tool Player in baseball is someone very rare who excels in all key areas of the game. They have the talent and abilities they need to be the best and play at the highest level, making them incredibly valuable to their team.</p>
                          <p className="specialText">A 7-Tool Advisor is similar.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="int-tier advisor-tier2">
                <div className="wrap">
                  <p className="leadin-text" data-ani="fadeInUp">They have a unique set of skills and traits that allow them to live their own life to the fullest while helping others to do the same. Some may come naturally and others are nurtured and developed over time, but the below seven traits define who our advisors are.</p>
                  <div className="grid-x align-center">
                    <div className="large-3 cell" data-ani="fadeInUp">
                      <div className="inner-cell">
                        <span>01</span>
                        <h2>Passionate</h2>
                        <p>Highly-driven & enthusiastic</p>
                      </div>
                    </div>
                    <div className="large-3 cell" data-ani="fadeInUp">
                      <div className="inner-cell">
                        <span>02</span>
                        <h2>Creative</h2>
                        <p>Next-level thinker & problem solver</p>
                      </div>
                    </div>
                    <div className="large-3 cell" data-ani="fadeInUp">
                      <div className="inner-cell">
                        <span>03</span>
                        <h2>Relational</h2>
                        <p>Values & appreciates people</p>
                      </div>
                    </div>
                    <div className="large-3 cell" data-ani="fadeInUp">
                      <div className="inner-cell">
                        <span>04</span>
                        <h2>Growing</h2>
                        <p>Always learning & advancing</p>
                      </div>
                    </div>
                    <div className="large-3 cell" data-ani="fadeInUp">
                      <div className="inner-cell">
                        <span>05</span>
                        <h2>Attentive</h2>
                        <p>Proactive & anticipates needs</p>
                      </div>
                    </div>
                    <div className="large-3 cell" data-ani="fadeInUp">
                      <div className="inner-cell">
                        <span>06</span>
                        <h2>Mindful</h2>
                        <p>Present & Aware</p>
                      </div>
                    </div>
                    <div className="large-3 cell" data-ani="fadeInUp">
                      <div className="inner-cell">
                        <span>07</span>
                        <h2>Adventurous</h2>
                        <p>Energetic & hungry for life</p>
                      </div>
                    </div>
                  </div>
                  <p className="leadin-text" data-ani="fadeInUp">Our culture is designed to magnify your strengths and support you in your growth areas, and as we sharpen one another and accomplish our goals, we’re able to pay it forward with the people we serve.</p>
                </div>
                <div className="wrap culture-last independence-story" data-ani="fadeInUp">
                  <h2>The One Seven Independence Story</h2>
                  <div id="buzzsprout-player-4795145"></div>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cta-bar text-center">
          <p>
            <NavLink className="cta-button" to="/dream"><span>Continue the Journey</span> <span>2 of 10</span></NavLink>
          </p>
        </div>
      </div>
    );
  }
}
