import React, { Component } from 'react';
import './App.scss';
import Header from './components/header';
import Footer from './components/footer';
import Homepage from './components/pages/homepage';
import Journey from './components/pages/journey';
import Dream from './components/pages/dream';
import Advisor from './components/pages/advisor';
import Expertise from './components/pages/expertise';
import Testimonials from './components/pages/testimonials';
import Transition from './components/pages/transition';
import NotFound from './components/pages/notfound';
import Story from './components/pages/our-story';
import Culture from './components/pages/our-culture';
import Team from './components/pages/our-team';
import LifeMap from './components/pages/lifemap';
import Blog from './components/pages/blog';
import FeaturedIn from './components/pages/featuredIn';
import BlogPost from './components/pages/blogpost';
import Book from './components/pages/book';
import {Route, Switch} from 'react-router-dom';
import { CSSTransition, TransitionGroup, } from 'react-transition-group';
import Nav from './components/nav';
// import Scrollbar from 'react-smooth-scrollbar';

class App extends Component {
  state = {
    hideNav: false,
    backg: ''
  };
  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  resize() {
    this.setState({hideNav: window.innerWidth <= 1023});
  }
  render(){
    return (
      <>
        <Nav/>
        <Header/>
        <Route render={({location}) => (
          <TransitionGroup>
            <CSSTransition
                key={location.key}
                timeout={
                  this.state.hideNav ? 0 : 750
                }
                classNames="transition"
              >
              <Switch location={location}>
                <Route exact path="/" component={Homepage} />
                <Route path="/living-your-best-life" component={Journey} />
                <Route path="/dream" component={Dream} />
                <Route path="/advisor" component={Advisor} />
                <Route path="/expertise" component={Expertise} />
                <Route path="/testimonials" component={Testimonials} />
                <Route path="/journey" component={Transition} />
                <Route path="/story" component={Story} />
                <Route path="/culture" component={Culture} />
                <Route path="/team" component={Team} />
                <Route path="/lifemap" component={LifeMap} />
                <Route exact path="/blog" component={Blog} />
                <Route exact path="/book" component={Book} />
                <Route exact path="/featured-in" component={FeaturedIn} />
                <Route path="/blog/:slug" component={BlogPost} />
                <Route component={NotFound} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        )} />
        <Footer/>
      </>
    );
  }
}

export default App;
