import React, { Component } from "react";
import Helmet from "react-helmet";
import Basic from '../scripts/basic';

export default class NotFound extends Component {
  render() {
    return (
      <div>
      <div className="transition-overlay"></div>
        <Basic/>
        <Helmet title="404 Not Found | 7-Tool Advisor by One Seven" />
        <div className="pageContent">
          <div className="titleContainer">
            <div className="bg-flex-wrapper" dangerouslySetInnerHTML={{__html: this.props.bg}} />
            <h1>404 Not Found</h1>
          </div>
        </div>
      </div>
    );
  }
}
